import React, { Component } from 'react';
import './App.css';
import QuestionnaireList from './QuestionnaireList.js';


class App extends Component {
  render() {
    return (
		<div>
			<QuestionnaireList webroot="./" />
		</div>
    );
  }
}

export default App;
