import React, { Component } from 'react';
import JSONBlockSource from './JSONBlockSource.js'
//import CalculationChangeListener from '../CalculationChangeListener.js'

class Input extends Component implements JSONBlockSource, CalculatorContributor
{

	
	currentMinTime = 0;
	currentMaxTime = 0;
	currentMinCost = 0;
	currentMaxCost = 0;
	currentMinMonthlyTime = 0;
	currentMaxMonthlyTime = 0;
	currentMinMonthlyCost = 0;
	currentMaxMonthlyCost = 0;
	currentDecisionSums = [];
	currentDestinationSums = [];
	commonstrings = null;

	getMinCostContribution()
	{
		return this.currentMinCost;
	}
	getMaxCostContribution()
	{
		return this.currentMaxCost;
	}
	getMinTimeContribution()
	{
		return this.currentMinTime;
	}
	getMaxTimeContribution()
	{
		return this.currentMaxTime;
	}
	getMinMonthlyCostContribution()
	{
		return this.currentMinMonthlyCost;
	}
	getMaxMonthlyCostContribution()
	{
		return this.currentMaxMonthlyCost;
	}
	getMinMonthlyTimeContribution()
	{
		return this.currentMinMonthlyTime;
	}
	getMaxMonthlyTimeContribution()
	{
		return this.currentMaxMonthlyTime;
	}
	getContributionForDecision(decisionId)
	{
		if (this.currentDecisionSums[decisionId] == null) 
		{
			return 0;
		}
		else return this.currentDecisionSums[decisionId];
	}
	
	getListOfDestinations()
	{
		var arrayToReturn = [];
		for (var key in this.selectionDestinations)
		{
			if (this.selectionDestinations[key] != null) for (var i = 0; i < this.selectionDestinations[key].length; i++)
			{
				var exists = false;
				for (var j = 0; j < arrayToReturn.length; j++) if (arrayToReturn[j] == this.selectionDestinations[key][i][1]) { exists=true; break; }
				if (!exists) arrayToReturn.push(this.selectionDestinations[key][i][1]);
			}
		}
		return arrayToReturn;
		
	}

	getContributionForDestination(destinationId)
	{
		if (this.currentDestinationSums[destinationId] == null) 
		{
		//	console.log("... sooo not there...");
			return 0;
		}
		else
		{
			//console.log("Dest : " + destinationId + " sum : " + this.currentDestinationSums[destinationId]);
			return this.currentDestinationSums[destinationId];
		}
	}

//	multiplierSets = [];
	//Note: this is not the same as the model the subclasses manage (one which is given by the container) - the subclasses decide what to store on hide, this
	//model is *only* used for the calculations done here.
	//ALSO, VERY IMPORTANT: Everything, even checkboxes have a numerical WEIGHT here, so this is not a true/false ever!
	selectionCurrentValues = [];
	selectionMultipliers = [];
	selectionMultiplierCurves = [];
	selectionDecisions = [];
	selectionDestinations = [];

	registerMultiplierSetForSelection(selection, multipliers, multipliercurves, decisions, destinations, value)
	{
		this.selectionMultipliers[selection] = multipliers;
		this.selectionMultiplierCurves[selection] = multipliercurves;
		this.selectionDecisions[selection] = decisions;
		this.selectionDestinations[selection] = destinations;
		this.selectionCurrentValues[selection] = value;

	}

	
	keyvals = [];

	fields = [];

	fieldOwners = [];

	contentroot="";

	label = "";

	changelistener = null;

	constructor(props)
	{
		super(props);
		this.contentroot = props.contentroot;
		this.calculator = props.calculator;
		this.calculator.registerContributor(this);
		this.changelistener = props.changelistener;
		this.changelistener.registerInputEntry(props.inputindex, this);
		this.commonstrings = props.commonstrings;
		for (var key in props.json)
		{
			switch (key)
			{
				case "fields":
					this.fields = props.json[key];
					break;
				case "label":
					this.label = props.json[key];
					break;
				default:
					this.keyvals[key] = props.json[key];
			}
		}
	}

	getV(key)
	{
		return this.keyvals[key];
	}

	readBlock()
	{
		console.log("Read input block");
	}


	curveFunction(type, value)
	{
		if (type !== 0) console.log("No support for non linear curve types yet...");
		return value;

	}

	singleRadioAutoHide()
	{
		var temp = [];
		temp["href"] = "lock";
		this.changelistener.handleAnchorClick(null,temp);
	}

	recalculateContributions(selections, values)
	{
		//console.log("Recalc");
		var i;
		//Why selectionS and valueS? Because some controls might have multiple effects at the same time and we don't want to refresh twice.
		for (i = 0; i < selections.length; i++)
		{
			this.selectionCurrentValues[selections[i]] = values[i];
		}
		this.currentMinTime = 0;
		this.currentMaxTime = 0;
		this.currentMinCost = 0;
		this.currentMaxCost = 0;
		this.currentMinMonthlyTime = 0;
		this.currentMaxMonthlyTime = 0;
		this.currentMinMonthlyCost = 0;
		this.currentMaxMonthlyCost = 0;
		this.currentDecisionSums = [];
		this.currentDestinationSums = [];
		for (var selection in this.selectionCurrentValues)
		{
			if (this.selectionCurrentValues[selection] !== 0)
			{
	//			console.log(selection + " is on and has value " + this.selectionCurrentValues[selection]);
				if ((this.selectionMultiplierCurves[selection] != null) &&
				    (this.selectionCurrentValues[selection] != null) &&
				    (this.selectionMultipliers[selection] != null) &&
				    (this.selectionMultipliers[selection][0] != null) &&
				    (this.selectionMultipliers[selection][1] != null) &&
				    (this.selectionMultipliers[selection][2] != null) &&
				    (this.selectionMultipliers[selection][3] != null) &&
				    (this.selectionMultipliers[selection][4] != null) &&
				    (this.selectionMultipliers[selection][5] != null) &&
				    (this.selectionMultipliers[selection][6] != null) &&
				    (this.selectionMultipliers[selection][7] != null) &&
				    (this.selectionMultiplierCurves[selection][0] != null) &&
				    (this.selectionMultiplierCurves[selection][1] != null) &&
				    (this.selectionMultiplierCurves[selection][2] != null) &&
				    (this.selectionMultiplierCurves[selection][3] != null) &&
				    (this.selectionMultiplierCurves[selection][4] != null) &&
				    (this.selectionMultiplierCurves[selection][5] != null) &&
				    (this.selectionMultiplierCurves[selection][6] != null) &&
				    (this.selectionMultiplierCurves[selection][7] != null)
				)
				{
					this.currentMinTime += this.curveFunction(
							this.selectionMultiplierCurves[selection][0],this.selectionCurrentValues[selection]
						)*this.selectionMultipliers[selection][0];
					this.currentMaxTime += this.curveFunction(
							this.selectionMultiplierCurves[selection][1],this.selectionCurrentValues[selection]
						)*this.selectionMultipliers[selection][1];
					this.currentMinCost += this.curveFunction(
							this.selectionMultiplierCurves[selection][2],this.selectionCurrentValues[selection]
						)*this.selectionMultipliers[selection][2];
					this.currentMaxCost += this.curveFunction(
							this.selectionMultiplierCurves[selection][3],this.selectionCurrentValues[selection]
						)*this.selectionMultipliers[selection][3];
					this.currentMinMonthlyTime += this.curveFunction(
							this.selectionMultiplierCurves[selection][4],this.selectionCurrentValues[selection]
						)*this.selectionMultipliers[selection][4];
					this.currentMaxMonthlyTime += this.curveFunction(
							this.selectionMultiplierCurves[selection][5],this.selectionCurrentValues[selection]
						)*this.selectionMultipliers[selection][5];
					this.currentMinMonthlyCost += this.curveFunction(
							this.selectionMultiplierCurves[selection][6],this.selectionCurrentValues[selection]
						)*this.selectionMultipliers[selection][6];
					this.currentMaxMonthlyCost += this.curveFunction(
							this.selectionMultiplierCurves[selection][7],this.selectionCurrentValues[selection]
						)*this.selectionMultipliers[selection][7];
				}
				
				if (this.selectionDecisions != null) if (this.selectionDecisions[selection] != null ) for (i = 0; i < this.selectionDecisions[selection].length; i++)
				{
					if (this.currentDecisionSums[this.selectionDecisions[selection][i][1]] == null)
					{
						this.currentDecisionSums[this.selectionDecisions[selection][i][1]] = 0;
					}
					this.currentDecisionSums[this.selectionDecisions[selection][i][1]]  += this.curveFunction(this.selectionDecisions[selection][i][2],this.selectionCurrentValues[selection])*this.selectionDecisions[selection][i][0];
				}
				if ((this.selectionDestinations[selection] != null) && (this.selectionDestinations[selection].length > 0))
				{
					for (i = 0; i < this.selectionDestinations[selection].length; i++)
					{
						if (this.currentDestinationSums[this.selectionDestinations[selection][i][1]] == null)
						{
							this.currentDestinationSums[this.selectionDestinations[selection][i][1]] = 0;
						}
						this.currentDestinationSums[this.selectionDestinations[selection][i][1]]  += this.curveFunction(this.selectionDestinations[selection][i][2],this.selectionCurrentValues[selection])*this.selectionDestinations[selection][i][0];
					}
				}
				
			}
		}
		
		this.changelistener.changed();
		
		this.calculator.changed();
	}

}

export default Input;
