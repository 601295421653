import * as React from 'react'
import { FlowChart, IChart, IConfig, IFlowChartComponents } from '@mrblenny/react-flow-chart'
import * as actions from '@mrblenny/react-flow-chart/src/container/actions'
import {mapValues, assign, clone} from 'lodash'
import IFlowChartWithStateProps from '@mrblenny/react-flow-chart'

class FlowChartWithStateNodeClickOverride extends React.Component<IFlowChartWithStateProps, IChart> {
	state: IChart
	stateActions = mapValues(actions, (func: any) =>
		(...args: any) => this.setState(func(...args)))

	myMap = null;

	constructor (props: IFlowChartWithStateProps) {
		super(props)
		this.state = props.initialValue;
		this.myMap = props.mymap;
	}
	
	render () {
		const { Components, config } = this.props;
		var myActions = this.stateActions;
		var mapref = this.myMap;
		myActions.onNodeClick = function( node )
		{
			mapref.nodeClicked(node.nodeId);
		}
		myActions.onDragNode = function() {}
		myActions.onLinkClick = function(node) {}

		return (
			<FlowChart
				chart={this.state}
				callbacks={myActions}
				Components={Components}
				config={config}
			/>
		);
  }
}

export default FlowChartWithStateNodeClickOverride;
