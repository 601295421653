import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import { Spin } from 'antd';
import { Affix } from 'antd';
import Questionnaire from './Questionnaire.js';

const TabPane = Tabs.TabPane;

class QuestionnaireList extends Component
{
	render() {
		if ((this.showSpinner === -1) || (this.showSpinner === 1))
		{
			return <div ref="tabscontainer1" />;
		}
		else
		{
			return <div ref="tabscontainer2" />;
		}

	}

	showSpinner = -1;
    	questionnaireSources = null;
	commonstrings = null;


	handlejsonResponse(response) {
		this.questionnaireSources=response.sources;
		this.commonstrings=response.commonstrings;
		var infotabentry = [this.commonstrings.infotab, "info.json"];
		if (this.commonstrings != null)
			if (this.commonstrings.infotab != null) infotabentry[0] = this.commonstrings.infotab;
		this.questionnaireSources.push(infotabentry);
		if (this.showSpinner === 1)
		{
			this.showSpinner = 0;
			this.setState({});
			this.componentDidMount();
		}
		this.showSpinner = 0;
	}

	componentDidMount()
	{
		if ((this.showSpinner === -1) || (this.showSpinner === 1))
		{
			var spinnerCallback = this;
			fetch(this.props.webroot+"configuration/questionnairelist.json").then(
				function(response)
				{
					return response.json();
				}
			).then(
				function(jsonlist)
				{
					spinnerCallback.handlejsonResponse(jsonlist);
				});
			
			if ((this.showSpinner === -1) || (this.showSpinner === 1))
			{
				this.showSpinner = 1;
				ReactDOM.render(<div> <Spin size="large" /></div>, this.refs.tabscontainer1);
			}
			
		}
		else
		{
			var tabs = [];
			for (var i=0; i < this.questionnaireSources.length; i++)
			{
				var tabtext = this.questionnaireSources[i][0];
				var lasttabtext = [];
				lasttabtext.push(<i key="infofielditalic">{this.questionnaireSources[i][0]}</i>);

				if (i == this.questionnaireSources.length -1 ) tabtext = lasttabtext;
				tabs.push( <TabPane tab={tabtext} key={i}><Questionnaire webroot={this.props.webroot} jsonsource={this.questionnaireSources[i][1]} commonstrings={this.commonstrings} /></TabPane> );
			}
			if (window.global_digiruori_running_in_ie)
			{
				var iewarn=[];
				iewarn.push(<Affix><div style={{color: "red", background:"yellow"}}>{this.commonstrings.noiesupport}</div></Affix>);
				ReactDOM.render(<div> <Tabs tabBarExtraContent={iewarn} defaultActiveKey="0">{tabs}</Tabs></div>, this.refs.tabscontainer2);
			}
			else
			{
				ReactDOM.render(<div> <Tabs defaultActiveKey="0">{tabs}</Tabs></div>, this.refs.tabscontainer2);
			}
		}
	}
}

export default QuestionnaireList;
