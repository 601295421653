import React, { Component } from 'react';
import 'antd/dist/antd.css';
import { Statistic, Row, Col, Button, Carousel, Card } from 'antd';
import Calculator from "./Calculator.js";
import CalculationChangeListener from "./CalculationChangeListener.js";
import Markdown from "react-markdown";




class DeductionTable extends Component implements CalculationChangeListener
{
	calculator = null;

	resolutions = null;

	allDecisionIds = [];

	labels = null;

	channels = null;

	button = null;

	contentroot = null;

	showestimates = true;

	timelabel = "Time (Min - Max)";
	expenselabel = "Monetary expense (Min - Max)";
	monthlytimelabel = "Monthly time (Min - Max)";
	monthlyexpenselabel = "Monthly monetary expense (Min - Max)";

	showAllDecisionsAboveZero = false;

	constructor(props)
	{
		super(props);
		this.calculator = props.calculator;
		this.calculator.registerChangeListener(this);
		this.resolutions = props.resolutions;
		this.labels = props.labels;
		this.button = props.button;
		this.channels = props.channels;
		this.contentroot = props.contentroot;
		this.showestimates = true;
		if (props.showestimates != null) this.showestimates = (props.showestimates == "false");
		if (props.timelabel != null) this.timelabel = props.timelabel;
		if (props.expenselabel != null) this.expenselabel = props.expenselabel;
		if (props.timelabel != null) this.monthlytimelabel = props.monthlytimelabel;
		if (props.expenselabel != null) this.monthlyexpenselabel = props.monthlyexpenselabel;
		this.showAllDecisionsAboveZero = props.showalldecisionsabovezero;


//		console.log("Check the labels and resolutions " + this.labels + " , " + this.resolutions);
		if ((this.labels != null) && (this.resolutions != null))
		{
//			console.log("Got both");
			if (this.labels.length != this.resolutions.length) 
			{
				//console.log("Differing amount of conclusionblocks and labels, IGNORING BOTH!")
				this.labels = [];
				this.resolutions = [];
			}
			for (var i = 0; i < this.resolutions.length; i++)
			{
				var subDecisionIds = [];
				for (var j = 0; j < this.resolutions[i].length; j++)
				{
					//The first option is always shown by default, it represent sum 0
					//IMPORTANT: Understand that allDecisionIds and resolutions are *not* in sync when it comes to indices!
					if ( j !== 0) subDecisionIds.push(this.resolutions[i][j].resid);
				}
				this.allDecisionIds[i] = subDecisionIds;
			}
		}
		else
		{
			this.labels = [];
			this.resolutions = [];
		}
	}


	updateDisplayedCalculations()
	{
//		console.log("Update calculations");
		this.showCarousel = false;
		//FIXME: This complains (and it should too...) when this is called during rendering... disable for now.
		this.setState({});
	}

	enablecarousel = (clickety) => {
		this.showCarousel = true;
		this.setState({});
	}


	rendround = 0;

	render() {
		if (this.rendround++ > 100000) this.rendround = 0;
//		console.log("Rendering res ");
		var timeMin = this.calculator.getSumOfMinTimes();
		var timeMax = this.calculator.getSumOfMaxTimes();
		var costMin = this.calculator.getSumOfMinCosts(); 
		var costMax = this.calculator.getSumOfMaxCosts();
		var monthlytimeMin = this.calculator.getSumOfMinMonthlyTimes();
		var monthlytimeMax = this.calculator.getSumOfMaxMonthlyTimes();
		var monthlycostMin = this.calculator.getSumOfMinMonthlyCosts(); 
		var monthlycostMax = this.calculator.getSumOfMaxMonthlyCosts();
		var time = "" + timeMin + " - " + timeMax;
		if (timeMax === 0) time = "" + timeMin + " - \u221e"
		var cost = "" + costMin + " - " + costMax;
		if (costMax === 0) cost = "" + costMin + " - \u221e"
		var monthlytime = "" + monthlytimeMin + " - " + monthlytimeMax;
		if (monthlytimeMax === 0) monthlytime = "" + monthlytimeMin + " - \u221e"
		var monthlycost = "" + monthlycostMin + " - " + monthlycostMax;
		if (monthlycostMax === 0) monthlycost = "" + monthlycostMin + " - \u221e"
		var decisionStatistics = [];
		var allDecisions = [];
		for ( var i = 0; i < this.labels.length; i++)
		{
//			console.log("Check label " + i);
			//console.log("When calling greatest show all is " + this.showAllDecisionsAboveZero);
			var decisions = this.calculator.getGreatestDecision(this.allDecisionIds[i], this.showAllDecisionsAboveZero);
			//console.log(decisions);
			if (decisions[0] == "default")
			{
				//console.log("Show default");
				decisionStatistics.push(<div key={i+"default"+this.rendround}><h3>{this.labels[i]}</h3> <i>{this.resolutions[i][0].text}</i><br/><br/></div>);
				//decisionStatistics.push(<Statistic title={this.labels[i]} value={this.resolutions[i][0].text}/>);
			}
			else
			{
				//console.log("Show others");
				var litemp = [];
				var divname = "";
				for (var j = 0; j < this.resolutions[i].length; j++)
				{
					for (var k = 0; k < decisions.length; k++)
					{
						if (decisions[k] == this.resolutions[i][j].resid)
						{
							divname += decisions[k]+"-"+i+"-";
							//decisionStatistics.push(<Statistic title={this.labels[i]} value={this.resolutions[i][j].text}/>);
							var mdheadline = [];
							mdheadline.push(<Markdown key={i+divname+this.rendround+"-hl"} escapeHtml={false} transformImageUri={this.transformimguri} source={this.resolutions[i][j].text}/>);

							litemp.push(<li key={i+divname+this.rendround+"-decision"}>{mdheadline}</li>);
							allDecisions.push(decisions[k]);
						}
					}
				}
				decisionStatistics.push(<div key={divname}><h3>{this.labels[i]}</h3><ul>{litemp}</ul></div>);
			}
		}
//		console.log("Out");

 		var drawButton = [];
		if ((this.button != null) && (this.button != "")) drawButton.push(<Button onClick={this.enablecarousel} style={{ marginTop: 16 }} type="primary">{this.button}</Button>);
		
		var carouselImages = [];
		var carousel = [];
		var mylogos = [];
		var myurls = [];
		var mybuffs = [];
		if (this.showCarousel)
		{
			//I'm sure this could be made better... check the json syntax, it's likely too complicated...
			for ( var c = 0; c < allDecisions.length; c++)
				for (var l = 0; l < this.channels.length; l++)
					if (this.channels[l].resid == allDecisions[c]) 
						for (var d = 0; d < this.channels[l].logos.length; d++) 
						{
							mylogos.push(this.channels[l].logos[d]);
							myurls.push(this.channels[l].urls[d]);
							mybuffs.push(this.channels[l].buffs[d]);
						}
			drawButton = [];
			for (var s =0; s < mylogos.length; s++)
			{
				carouselImages.push(<div><a href={myurls[s]}><img style={{display:"block", marginLeft:"auto", marginRight:"auto"}} src={this.contentroot+mylogos[s]}/></a></div>);
			}
			carousel.push(<Row><Col align="center">Alla olevat tahot tuottavat kaipaamiasi palveluja. <br/>Huomaa: Klikkaamalla linkkej&auml; siirryt pois palvelustamme ja tekem&auml;si valinnat katoavat selaimen muistista.</Col></Row>);
			carousel.push(<Row><Col align="center"> &nbsp; </Col></Row>);
			for (var f = 0; f < mybuffs.length; f++) carousel.push(<Row><Col align="center"><a href={myurls[f]}>{mybuffs[f]}</a></Col></Row>);
			carousel.push(<Row><Col><Card><Carousel autoplay>{carouselImages}</Carousel></Card></Col></Row>);
		}
		var calculations = [];
		if (this.showcalculations)
		{
			calculations.push(
				<div key={"calckey" + this.rendround}>
				<Row gutter={24}>
					<Col span={12}>
						<Statistic title={this.timelabel} value={time} />
					</Col>
					<Col span={12}>
						<Statistic title={this.expenselabel} value={cost} precision={0} />
					</Col>
				</Row>
				<Row gutter={24}>
					<Col span={12}>
						<Statistic title={this.monthlytimelabel} value={monthlytime} />
					</Col>
					<Col span={12}>
						<Statistic title={this.monthlyexpenselabel} value={monthlycost} precision={0} />
					</Col>
				</Row>
				</div>
			);
		}

		return (
			<div>
			{calculations}
			<Row>
				<Col span={24}>
					{decisionStatistics}
					{drawButton}
				</Col>
			</Row>
			{carousel}
			</div>
		);
	}
}

export default DeductionTable;
