import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import QuestionBlock from './QuestionBlock.js';
import QuestionBlockSet from './QuestionBlockSet.js';
import DeductionTable from './DeductionTable.js';
import FullMap from './FullMap.js';
import 'antd/dist/antd.css';
import { Card, Col, Row, Spin, Carousel, Collapse, Affix } from 'antd';
import { isMobile } from "react-device-detect";
import Calculator from "./Calculator.js";
import Markdown from "react-markdown";
import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;


class Questionnaire extends Component {

	contentroot = "";
	name = "";
	general = "";
	images = [];
	questionblockshards = [];
	isStatic = true;	
	showResolutions = true;
	showResolutionsForced = true;
	showResolutionsEndPath = true;
	showestimates = true;
	dynamicInitialEntries = 1;
	showfullmap=false;
	commonstrings=[];
	nodeRefs = [];

	timelabeltext = null;
	expenselabeltext = null;
	monthlytimelabeltext = null;
	monthlyexpenselabeltext = null;

	instructionsFirst = false;

	showSpinner = -1;

	generalRend = [];

	usecategories = false;
	categorynames =[];
	showestimates = true;

	categorizequestions = false;

	questionblockshardsCategorized = [];

	transformimguri = (uri) =>
	{
		return this.contentroot+uri;
	}
	
	handleHtmlResponse(response)
	{
		this.generalContent = response;
		this.generalRend.push(<Markdown key="genmark" escapeHtml={false} transformImageUri={this.transformimguri} source={this.generalContent}/>);
		if (this.showSpinner === 1)
		{
			this.showSpinner = 0;
			this.setState({});
			this.componentDidMount();
		}
		this.showSpinner = 0;
	}


	resobuttontext = null;
	deliverychannels = null;

	maplayerheight = 200;
	mapcolumnwidth = 250;
	mapcolumnpad = 10;
	maprowtoppad = 10;
	mapalign = "left";
	mapwidth = 8;
	imageAreaScaling = 100;

	noColumns=false;

	instructionsalwayson = false;

	myCurrentMap = null;

	setOptionpath(ids)
	{
		//TODO: Optionpaths are a problematic construct. Essentially the thinking goes: If we have paths, can a single click on a node select an entire path
		//	and then jump on the node resulting from that path. There are multiple issues with this, though, starting with the fact that this does not
		//	encourage the user to explore, which is kind of the point of this whole thing... However, it would Rock for debugging :-).
		//console.log("Set option path to " + ids + " now all we need to do is JUMP!-)");
	}

	jumpToBlock(id, iscategory)
	{
		//console.log("Jump to block " + id);
		var refid = null;
		if (iscategory) refid = "cat-" + id;
		else refid = "node-" + id;
		if (!isMobile && (this.nodeRefs[refid] != null)) 
			if (this.nodeRefs[refid].current != null) this.nodeRefs[refid].current.scrollIntoView({behavior: "smooth"});
			else (console.log(this.nodeRefs[refid]));
	}

	blockFullyDone(category)
	{
		if (!isMobile && (this.myCurrentMap != null)) 
		{
			this.myCurrentMap.setClearedNode(category);
		}
	}

	blockUnderway(category)
	{
		if (!isMobile && (this.myCurrentMap != null)) 
		{
			this.myCurrentMap.unSetClearedNode(category);
		}
	}

	registerMap(map)
	{
		//console.log("Registering a new map");
		this.myCurrentMap = map;
	}

	showalldecisionsabovezero = false;

	handlejsonResponse(res) {
		this.contentroot = this.props.webroot+"content/"+res.contentroot+"/";
		this.name = res.name;
		if (res.general != null) this.general = res.general;
		if (res.images != null) this.images = res.images;
		if (res.resolutions != null) this.resolutions = res.resolutions;
		if (res.resolutionlabels != null) this.resolutionLabels = res.resolutionlabels;
		if (res.resobuttontext != null) this.resobuttontext = res.buttontext;
		if (res.deliverychannels != null) this.deliverychannels = res.deliverychannels;
		if (res.timelabeltext != null) this.timelabeltext = res.timelabeltext;
		if (res.expenselabeltext != null) this.expenselabeltext = res.expenselabeltext;
		if (res.imageareascaling != null) this.imageAreaScaling = res.imageareascaling;
		if (res.monthlytimelabeltext != null) this.monthlytimelabeltext = res.monthlytimelabeltext;
		if (res.monthlyexpenselabeltext != null) this.monthlyexpenselabeltext = res.monthlyexpenselabeltext;
		if (res.showestimates != null) this.showestimates = (res.showestimates == "true");
		if (res.showfullmap != null) this.showfullmap=(res.showfullmap == "true");
		if (res.maplayerheight != null) this.maplayerheight=res.maplayerheight;
		if (res.mapcolumnwidth != null) this.mapcolumnwidth=res.mapcolumnwidth;
		if (res.mapcolumnpad != null) this.mapcolumnpad=res.mapcolumnpad;
		if (res.maprowtoppad != null) this.maprowtoppad=res.maprowtoppad;
		if (res.mapalign != null) this.mapalign=res.mapalign;
		if (res.nocolumns != null) this.noColumns=(res.nocolumns=="true");
		if (res.categorynames != null) this.categorynames = res.categorynames;
		this.showalldecisionsabovezero = false;
		if (res.showalldecisionsabovezero != null) this.showalldecisionsabovezero = (res.showalldecisionsabovezero == "true");
		//console.log("decs: " + res.showalldecisionsabovezero);
		if (res.usecategories != null) this.usecategories = (res.usecategories=="true");
		if (res.categorizequestions != null) this.categorizequestions = (res.categorizequestions=="true");
		if (res.overridestrings != null)
		{
			for (var keys in res.overridestrings)
			{
				this.commonstrings[keys] = res.overridestrings[keys];
			}
		}
		var mapwidthpercent = 25;
		if (res.mapwidthpercent != null) mapwidthpercent=res.mapwidthpercent;
		this.mapwidth = Math.floor((mapwidthpercent / 100) * 24);
		
		this.isStatic = true;
		this.showResolutions = true;
		this.showResolutionsEndOfPath = true;
		this.dynamicInitialEntries = 1;
		if (res.type == "dynamic")
		{
			this.isStatic = false; 
			this.showResolutions = false;
			this.showResolutionsEndOfPath = true;
			//TODO: These are now per category, right!?
			if (res.dynamicinitialentries != null)
				if (res.dynamicinitialentries > 1) this.dynamicInitialEntries = res.dynamicinitialentries;
		}
		if (res.showresolutions != null) this.showResolutionsForced = this.showResolutions = (res.showresolutions == "true");
		if (res.showresolutionsendofpath != null) this.showResolutionsEndPath = (res.showresolutionsendofpath == "true");
		if (res.instructionsfirst == "true") this.instructionsFirst = true;
		if (res.instructionsfirst == "false") this.instructionsFirst = false;
		if (res.instructionsalwayson == "true") this.instructionsalwayson = true;
		if (res.instructionsalwayson == "false") this.instructionsalwayson = false;
		if (res.questionblocks != null) 
		for (var i = 0; i < res.questionblocks.length; i++)
		{
			this.questionblockshards.push(res.questionblocks[i]);
			if (this.categorizequestions)
			{
				var appended = false;
				for (var j = 0; j < this.questionblockshardsCategorized.length; j++)
				{
					if (this.questionblockshardsCategorized[j][0].category == res.questionblocks[i].category)
					{
						this.questionblockshardsCategorized[j].push(res.questionblocks[i]);
						appended = true;
						break;
					}
				}
				if (!appended)
				{
					this.questionblockshardsCategorized[this.questionblockshardsCategorized.length] = [];
					this.questionblockshardsCategorized[this.questionblockshardsCategorized.length-1].push(res.questionblocks[i]);
				}
			}
		}
		//console.log(this.questionblockshardsCategorized);

		if (this.general != "")
		{
			var spinnerCallback = this;
			fetch(this.contentroot+this.general).then(
				function(response)
				{
					return response.text();
				}
			).then(
				function(htmlContent)
				{
					spinnerCallback.handleHtmlResponse(htmlContent);
				});
		}
		else
		{
			if (this.showSpinner === 1)
			{
				this.showSpinner = 0;
				this.setState({});
				this.componentDidMount();
			}
			this.showSpinner = 0;
		}
	}

	calculator = null;

	constructor(props)
	{
		super(props);
		this.calculator = new Calculator();
		var jsonhandler = this;
		this.commonstrings=props.commonstrings;
		fetch(this.props.webroot+"/questionnaires/"+props.jsonsource).then(
			function(response)
			{
				return response.json();
			}
		).then(
			function(jsonlist)
			{
				jsonhandler.handlejsonResponse(jsonlist);
			});
	}

	render() {
		if ((this.showSpinner === -1) || (this.showSpinner === 1))
		{
			return <div><div ref="questionblockcontainer1" /></div>;
		}
		else
		{
			return <div><div ref="questionblockcontainer2" /></div>;
		}


	}

	already = false;
	carouselToBottom = false;


	resolutionarea = [];
	questionblocks = [];
	componentDidMount()
	{
		if ((this.showSpinner === -1) || (this.showSpinner === 1))
		{
			this.showSpinner = 1;
			ReactDOM.render(<div> <Spin size="large" /></div>, this.refs.questionblockcontainer1);

		}
		else
		{
			var row = 0;
			if (this.showResolutions)
			{
				//console.log("Showing resolutions : " + this.showalldecisionsabovezero);
				this.resolutionarea.push(<Row key="resoarea"><Col><DeductionTable showalldecisionsabovezero={this.showalldecisionsabovezero} calculator={this.calculator} showestimates={this.showestimates} resolutions={this.resolutions} labels={this.resolutionLabels} button={this.resobuttontext} channels={this.deliverychannels} monthlytimelabel={this.monthlytimelabeltext} monthlyexpenselabel={this.monthlyexpenselabeltext} timelabel={this.timelabeltext} expenselabel={this.expenselabeltext} contentroot={this.contentroot} commonstrings={this.commonstrings}/></Col></Row>);
			}
			if (this.already)
			{
				if (this.showResolutionsEndOfPath)
				{
					if (!this.showResolutions)
					{
						//console.log("Clearing resolutions");
						this.resolutionarea=[];
					}
				}
				return;
			}
			else this.already = true;
			if (!this.showResolutions)
			{
				//console.log("Clearing resolutions");
				this.resolutionarea=[];
			}
			var i = 0;
			var carouselImages = [];
			if (this.images != null) for (i =0; i < this.images.length; i++)
			{
					var carouimage = "carouimage" + i;
					carouselImages.push(<div key={carouimage}><img style={{display:"block", marginLeft:"auto", marginRight:"auto", maxWidth: this.imageAreaScaling + "%", maxHeight: this.imageAreaScaling + "%"}} src={this.contentroot+this.images[i]}/></div>);
			}	
			if (!isMobile && (carouselImages.length > 0))
			{
				this.questionblocks.push(<Row type="flex" justify="space-around" key={row++}><Col span={12}><Card bordered={false}>{this.generalRend}</Card></Col>
					<Col span={12}><Card bordered={false}>
						<Row><Col><div> </div></Col></Row>
						<Row justify="center"><Col><Carousel autoplay>{carouselImages}</Carousel></Col></Row>
						<Row><Col><div> </div></Col></Row>
					</Card></Col>
				</Row>);
			}
			else
			{
				this.questionblocks.push(<Row><Col><Card>{this.generalRend}</Card></Col></Row>);
				if (carouselImages.length != 0) this.questionblocks.push(<Row><Col><Card><Carousel autoplay>{carouselImages}</Carousel></Card></Col></Row>);
			}
	
			if (this.categorizequestions)
			{
				var tempBlocks = [];
				var tempPanels = [];
				var firstrefhtml = [];
				var firstrefcatlabel = null;
				for ( var q = 0; q < this.questionblockshardsCategorized.length; q++)
				{
					var name = this.questionblockshardsCategorized[q][0].category;
					var nextrefhtml = [];
					nextrefhtml.push(<div key={name+"-"+q} jumpto="none">&nbsp;</div>);
					//NOTE: This slightly convoluted system needs to exist because the scrolling did not jump to the right location... well, it still doesn't on FF.
					if (q == 0)
					{
						if (name != null) 
						{
							firstrefcatlabel = "cat-"+name;
							this.nodeRefs[firstrefcatlabel] = React.createRef();
							firstrefhtml.push(<div key={firstrefcatlabel+"-div-"+q} ref={this.nodeRefs[firstrefcatlabel]} jumpto={firstrefcatlabel}>&nbsp;</div>);
						}
						//console.log("Created first ref for " + firstrefcatlabel);
						
					}
					if ((q+1) < this.questionblockshardsCategorized.length)
					{
						var nextname = this.questionblockshardsCategorized[q+1][0].category;
						if (nextname != null) 
						{
							var nextrefcatlabel = "cat-"+nextname;
							this.nodeRefs[nextrefcatlabel] = React.createRef();
							nextrefhtml=[];
							nextrefhtml.push(<div key={nextrefcatlabel+"-div-"+q} ref={this.nodeRefs[nextrefcatlabel]} jumpto={nextrefcatlabel}>&nbsp;</div>);
						}
						//console.log("Created ref for " + nextrefcatlabel);
					}
					for (var n = 0; n < this.categorynames.length; n++) 
						if (name == this.categorynames[n].catid)
						{
							name = this.categorynames[n].catlabel;
							break;
						}
					if (this.questionblockshardsCategorized[q].length > 0)
					{
						//console.log("Embed ref for " + nextrefcatlabel);
						tempPanels.push(""+q);
						tempBlocks.push(<Panel header={name} key={""+q}><QuestionBlockSet noderefs={this.nodeRefs} categoryid={this.questionblockshardsCategorized[q][0].category} instructionsalwayson={this.instructionsalwayson} instructionsfirst={this.instructionsFirst} myhost={this} initialentries={this.dynamicInitialEntries} calculator={this.calculator} isstatic={this.isStatic} contentroot={this.contentroot} questionblockshards={this.questionblockshardsCategorized[q]} nocolumns={this.noColumns} commonstrings={this.commonstrings}/>{nextrefhtml}</Panel>);
					}
				}
				//console.log("Embed ref for " + firstrefcatlabel);
				this.questionblocks.push(<div key={firstrefcatlabel+"-"+q}>{firstrefhtml}<Collapse key="catpanel" defaultActiveKey={tempPanels}>{tempBlocks}</Collapse></div>);
			}
			else
			{	
				if (this.questionblockshards.length > 0)
				{
					this.questionblocks.push(<QuestionBlockSet noderefs={this.nodeRefs} instructionsalwayson={this.instructionsalwayson} instructionsfirst={this.instructionsFirst} myhost={this} initialentries={this.dynamicInitialEntries} calculator={this.calculator} isstatic={this.isStatic} contentroot={this.contentroot} questionblockshards={this.questionblockshards} nocolumns={this.noColumns} commonstrings={this.commonstrings}/>);
				}
			}
			return this.componentDidUpdate();
		}

	}
	startRenderingDeductions(check)
	{
		//console.log("Deduction rendering started: " + check);
		//For statics, resolutions are either shown or not:
		if (this.isStatic) return;
		if (this.showResolutionsForced) return;
		else
		{
			if (this.showResolutionsEndOfPath)
			{
				this.showResolutions = check;
				this.setState({});
				this.componentDidMount();
			}
		}

	}


	moveCarouselToBottomOfPage()
	{
		this.carouselToBottom = true;
		this.setState({});
	}

	componentDidUpdate()
	{
		//console.log("Start update");
		var scale = 1;
		var scaletext = "" + scale;
		var scalefunc = "scale(" + scale +")";
		var map = [];
		map.push(<Affix key="mapaffix"><div key="maparea" style={{MozTransformOrigin : "0 0", MozTransform : {scalefunc}, zoom : {scaletext}}}><FullMap questionnaire={this} align={this.mapalign} layerheight={this.maplayerheight} columnwidth={this.mapcolumnwidth} columnpad={this.mapcolumnpad} rowtoppad={this.maprowtoppad} questionblockshards={this.questionblockshards} categorynames={this.categorynames} usecategories={this.usecategories}/></div></Affix>);
//		if (this.noColumns)
//		{
			if (this.showfullmap)
			{
				if (isMobile)
				{

					ReactDOM.render(<Tabs defaultActiveKey="qst"><TabPane tab={this.commonstrings.maptab} key={"map"}> <Row><Col>{map}</Col></Row></TabPane><TabPane tab={this.commonstrings.questionnairetab} key="qst"> <Row><Col><div><div>{this.questionblocks}</div><div>{this.resolutionarea}</div></div></Col></Row></TabPane></Tabs>, this.refs.questionblockcontainer2);
				}
				else
				{
					ReactDOM.render(<Row><Col span={this.mapwidth}>{map}</Col><Col span={24-this.mapwidth}><div><div>{this.questionblocks}</div><div>{this.resolutionarea}</div></div></Col></Row>, this.refs.questionblockcontainer2);
				}
			}
			else 
			{
				ReactDOM.render(<div><div>{this.questionblocks}</div><div>{this.resolutionarea}</div></div>, this.refs.questionblockcontainer2);
			}
//		}
	}
}

export default Questionnaire;
