import React from 'react';
import 'antd/dist/antd.css';
import { Slider } from 'antd';

          
import Input from './Input.js';

class InputSlider extends Input {

	tooltipFormatter(value)
	{
		return "" + value;
	}


	render() {
		return (
			<Slider defaultValue={30} tipFormatter={this.tooltipFormatter} />
		);
	}
}

export default InputSlider;
