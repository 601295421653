import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import QuestionBlock from './QuestionBlock.js';
import 'antd/dist/antd.css';
import { Card, Col, Row, Spin, Carousel } from 'antd';
import { isMobile } from "react-device-detect";
import Calculator from "./Calculator.js";
import Markdown from "react-markdown";


class QuestionBlockSet extends Component {

	contentroot = "";
	questionblockshards = [];
	isStatic = true;	
	minimumToRenderTo = 0;
//	currentStackNumber = 0;

	calculator = null;

	questionblocksetSelectedOptions = [];

	instructionsFirst = false;

	noColumns = false;

	commonstrings=null;

	instructionsalwayson = false;

	categoryid = null;

	constructor(props)
	{
		super(props);
		this.categoryid = props.categoryid;
		this.commonstrings = props.commonstrings;
		this.calculator = props.calculator;
		this.isStatic = props.isstatic;
		this.contentroot = props.contentroot;
		this.questionblockshards = props.questionblockshards;
		this.myhost = props.myhost;
		this.instructionsFirst = props.instructionsfirst;
		this.instructionsalwayson = props.instructionsalwayson;
		this.noColumns = props.nocolumns;
		this.nodeRefs = props.noderefs;
		if (this.isStatic) 
		{
			this.minimumToRenderTo = this.questionblockshards.length;
		}
		else 
		{
			this.minimumToRenderTo = props.initialentries;
			this.dependencyMap = this.recursivelyCreateDependencyMap(null, null);
			//console.log("THE MAP:");
			//console.log(this.dependencyMap);
		}
	}

	//Dependency map is the tree starting from root, allNodes is a shortcut/lookup table to jump to a branch.
	//Note: This does not, obviously, work across the BlockSets at the moment, which is unfortunate... each block set has it's own map for now and there are no cross effects.

	dependencyMap = [];
	allNodesInMap = [];

	recursivelyCreateDependencyMap(shard, pathSoFar)
	{
		var newPath = [];
		if (pathSoFar != null) for (var r = 0; r < pathSoFar.length; r++) newPath[r] = pathSoFar[r];
		var toReturn = [];
		//Init, construct root of map
		if (shard == null)
		{
			//Initial entries in root node and therefore always visible
			for (var i = 0; i < this.minimumToRenderTo; i++)
			{
				newPath[newPath.length] = this.questionblockshards[i].questionblockid;
				toReturn[this.questionblockshards[i].questionblockid] = this.recursivelyCreateDependencyMap(this.questionblockshards[i], newPath);
				this.allNodesInMap[this.questionblockshards[i].questionblockid] = toReturn[this.questionblockshards[i].questionblockid];
			}
		} else
		//Creating a subnode
		if ((shard != null) && (shard.inputs != null))
		{
			for (var i = 0; i < shard.inputs.length; i++)
			{
//				console.log("Creating a tree for shard " + shard.questionblockid + " it has " + shard.inputs[i].fields.length + " fields.");
				for (var j = 0; j < shard.inputs[i].fields.length; j++)
				{
					if (shard.inputs[i].fields[j].destinations == null)
					{
	//					console.log("No destinations for field " + j);
						break;
					}
	//				console.log("  Field '" + shard.inputs[i].fields[j].label + "' has " + shard.inputs[i].fields[j].destinations.length + " destinations.");
					for (var k = 0; k < shard.inputs[i].fields[j].destinations.length; k++)
					{
	//					console.log("Destination : " + shard.inputs[i].fields[j].destinations[k][1]);
						var existsInPath = false;
						for (var c = 0; c < newPath.length; c++)
						{
							if (shard.inputs[i].fields[j].destinations[k][1] == newPath[c])
							{	
	//							console.log("!!!!!!Node + " + newPath[c] + " was already in path, break recursion due to cycle.");
								existsInPath = true;
								break;
							}
						}
						if (!existsInPath)
						{
							var newShard = null;
							for (var l = 0; l < this.questionblockshards.length; l++)
							{
								if (this.questionblockshards[l].questionblockid == shard.inputs[i].fields[j].destinations[k][1])
								{
									newShard = this.questionblockshards[l];
								}
							}
							if (newShard != null)
							{
								newPath[newPath.length] = newShard.questionblockid;
								toReturn[shard.inputs[i].fields[j].destinations[k][1]] = this.recursivelyCreateDependencyMap(newShard, newPath);
								this.allNodesInMap[shard.inputs[i].fields[j].destinations[k][1]] = toReturn[shard.inputs[i].fields[j].destinations[k][1]];
							}
							else
							{
								//Leave this log message for content creation --V
								console.log("Node " + shard.inputs[i].fields[j].destinations[k][1] + " does not exist, please check and define it!");
								//Leave this log message for content creation --^ 
							}
						}
					}
				}
			}
		} else
		//Leave this log message for content creation --V
		console.log("Unexpected case while building dependencies, are you sure you've defined all targets? Quit recursion for this branch.");
		//Leave this log message for content creation --^ 
		return toReturn;

	}

	blockDependentOnNode(seek, from, ignore)
	{
		//console.log("Is block " + seek + " dependent on node " + from + " if we ignore " + ignore + " starting from node ");
		for (var key in this.allNodesInMap[from])
		{
			//console.log("Check subkey " + key);
			if (key == seek) 
			{
//				console.log("--> Yes");
				return true;
			}
			//FIXME: Recursive check is problematic when two options lead to the same block
			//	 Yet at the same time recursion also works correctly for some cases.
		//	if (key != ignore) if (this.blockDependentOnNode(seek, key, ignore)) return true;
		}
//		console.log("--> No");
		return false;
	}

	//When a change is made into a node, all the related nodes must be pruned from the list of visible nodes.
	isItSafeToRemoveBlocks(blocksAfterClear, clearstackupto)
	{
	//	console.log("Start check");
	//	console.log(this.allNodesInMap);
		var toReturn = [];
		var verifyVisibility = [];
		var modnode = this.visibleBlockIds[clearstackupto];
		var deletedDependencies = [];
		for (var i = 0; i < this.visibleBlockIds.length; i++)
		{
			if ( i == clearstackupto )
			{
		//		console.log("Not scanning for modnode: " + modnode);
			}
			else
			{
				for (var j = 0; j < blocksAfterClear.length; j++)
				{
	//				console.log("Do I keep " + blocksAfterClear[j] + " comparing it to " + this.visibleBlockIds[i]);
					if (this.blockDependentOnNode(blocksAfterClear[j], this.visibleBlockIds[i], modnode))
					{
						var testDepDeletion = true;
						for (var k = 0; k < deletedDependencies.length; k++)
						{
							if (this.visibleBlockIds[i] == deletedDependencies[k])
							{
								testDepDeletion = false;
								break;
							}
						}
						if (testDepDeletion)
						{	
							//console.log(blocksAfterClear[j] + " depends on " + this.visibleBlockIds[i] + " ignoring " + modnode + " --> keep for now");
							verifyVisibility[verifyVisibility.length] = blocksAfterClear[j];
						}
						else
						{
							//console.log(blocksAfterClear[j] + " depended on " + this.visibleBlockIds[i] + " ignoring " + modnode + ", but the dependency was deleted.");
						}
					}
					else
					{
						deletedDependencies[deletedDependencies.length] = blocksAfterClear[j];
					}
				}
			}
		}
		var fullList = [];
		for (var i = 0; i < clearstackupto + 1; i++)
		{
			fullList[fullList.length] = this.visibleBlockIds[i];
		}
		for (var i = 0; i < verifyVisibility.length; i++)
		{
			fullList[fullList.length] = verifyVisibility[i];
		}
//		console.log("Making sure that for each node in the nodestobeleftlist a direct supernode exists.");
		for (var i = 0; i < verifyVisibility.length; i++ )
		{
			var found = false;
			for (var j = fullList.length; j >= 0; j--)
			{
				if (verifyVisibility[i] != fullList[j])
				{
					for (var key in this.allNodesInMap[fullList[j]]) 
						if (key == verifyVisibility[i]) {
							found = true;
							//Get rid of dupes:
							var dupe = false;
							for (var j = 0; j < toReturn.length; j++) if (toReturn[j] == key) { dupe = true; break};
							if (!dupe)
							{
								//console.log("Still adding " + key);
								toReturn[toReturn.length] = key;
							}
							break;
						}
				}
				if (found) break;
			}
//			if (!found) console.log("Still need to remove " + verifyVisibility[i]);
		}
//		console.log("---------");
//		console.log(toReturn);	
		//console.log("Keeping " + toReturn.length + " entries.");
		return toReturn;
	}


	questionblocks = [];

	startRenderingDeductions(check)
	{
		this.myhost.startRenderingDeductions(check);
	}
/*
	addBlockAndRerender(blockname)
	{
		//console.log("Here too");
		var dummy = [blockname];
		this.addBlocksAndRerender(dummy);
	}
*/
/*
		for (var i = 0; i < this.questionblockshards.length; i++)
		{
			if (this.questionblockshards[i]["questionblockid"] == blockname) 
			{
				this.pushBlockForShard(i);
				break;
			}
		}
		this.setState({});
	}
*/

	statusOfAllBlocks = [];

	
	blockSelectionDone(id)
	{
		this.statusOfAllBlocks[id] = true;
	}

	checkOverallBlockStatus()
	{
		var temp = false;
		for (var i = 0; i < this.visibleBlockIds.length; i++)
		{
			if (this.statusOfAllBlocks[this.visibleBlockIds[i]] == null) return false;
			temp = this.statusOfAllBlocks[this.visibleBlockIds[i]] || temp;
			if (!temp) return false;
		}
		return true;
	}


	//This hack here is important for React: the rowid is changed to reflect the fact that a redraw is reallyreallyneeded :-)
	dapush = "";
	addBlocksAndRerender(blocknames, openUnlocked)
	{
		//if (blocknames.length == 0) console.log("No new blocks were added!");
		for (var j = 0; j < blocknames.length; j++)
		{
			var blockname = blocknames[j];
			for (var i = 0; i < this.questionblockshards.length; i++)
			{
				if (this.questionblockshards[i]["questionblockid"] == blockname) 
				{
					//console.log("Here, pushing shard " + blockname);
					this.dapush += blockname+"-"+i+"-";
					//console.log("Dapush is " + this.dapush);
					this.pushBlockForShard(i, openUnlocked);
				}
			}
		}
		this.setState({});
	}

	//Yet another hack to give unique rownames (dapush turned out to be handy for debugging too :-)
	clearcounter = 0;

	rerender(clearstackupto)
	{
		this.dapush="clear-" + (this.clearcounter++) + "-" + clearstackupto + "-";
//		console.log("Clearing stack upto " + clearstackupto);
		var blocksAfterClear = [];
		for (var i = (clearstackupto + 1); i < this.visibleBlockIds.length; i++)
		{
			blocksAfterClear.push(this.visibleBlockIds[i]);
			this.dapush+=this.visibleBlockIds[i]+"-";
		}
//		console.log("Might get rid of blocks : " + blocksAfterClear);
		var keepBlocks = this.isItSafeToRemoveBlocks(blocksAfterClear, clearstackupto);
//		console.log("We need to keep blocks : " + keepBlocks);
		if (this.minimumToRenderTo > (clearstackupto + 1))
		{
//			console.log("Branch 1: " + this.minimumToRenderTo);
			if (!isMobile && !this.noColumns) 
			{
				var length = Math.floor(this.minimumToRenderTo/2);
				//console.log("New length for questionblocks is " + length);
				if (length == 0) this.questionblocks = [];
				else this.questionblocks.length = length;
			}
			else
			{
				this.questionblocks.length = this.minimumToRenderTo;
			}
			this.pushedElements.length = this.minimumToRenderTo;
			this.visibleBlockIds.length = this.minimumToRenderTo;
			//this.questionblocksetSelectedOptions.length = this.minimumToRenderTo;
			this.statusOfAllBlocks.length = this.minimumToRenderTo;
			this.unregisterBlocksDownto(this.minimumToRenderTo);
		}
		else
		{
//			console.log("Branch 2: " + this.minimumToRenderTo + " lengths are : " + this.questionblocks.length + ", " + this.pushedElements.length + ", " + this.visibleBlockIds.length + ", " + this.questionblocksetSelectedOptions.length);
			if (!isMobile && !this.noColumns) 
			{
				var length = Math.floor((clearstackupto+1)/2);
//				var hadALeftover = false;
//				if (((clearstackupto+1)%2) == 1) hadALeftover = true;
				//console.log("New length for questionblocks is " + length + " and mod is " + hadALeftover);
				if (length == 0) this.questionblocks = [];
				else this.questionblocks.length = length;
				//console.log("Question blocks are now:");
				//console.log(this.questionblocks);
			}
			else
			{
				this.questionblocks.length = clearstackupto + 1;
			}
			this.pushedElements.length = clearstackupto + 1;
			this.visibleBlockIds.length = clearstackupto + 1;
			//this.questionblocksetSelectedOptions.length = clearstackupto + 1;
			this.statusOfAllBlocks.length = clearstackupto + 1;
			this.unregisterBlocksDownto(clearstackupto + 1);
			//console.log("Branch 2 done: " + this.minimumToRenderTo + " lengths are : " + this.questionblocks.length + ", " + this.pushedElements.length + ", " + this.visibleBlockIds.length + ", " + this.questionblocksetSelectedOptions.length);
		}
//		console.log("---------VISIBLE NOW--------");
//		console.log(this.visibleBlockIds);
//		console.log("---------KEEPING " + keepBlocks.length + " BLOCKS-----");
		
		if (keepBlocks.length > 0)
		{
			//console.log("S1");
			this.addBlocksAndRerender(keepBlocks, true);
		}
		else
		{
			//console.log("S2");
//			if (!this.blockPushed) console.log("No new blocks were added");
//			else this.blockPushed = false;
			this.setState({});
		}
	}

//	blockPushed = false;

	wasRendered = false;

	pushedElements = [];
	visibleBlockIds = [];

	registeredBlocks = [];

	registerBlockForIndex(block, index)
	{
		//console.log("Registering a block of inputs in index " + index);
		if (this.registeredBlocks[index] != null) this.registeredBlocks[index].unregisterAllContributors();
		this.registeredBlocks[index] = block;
	}

	unregisterBlocksDownto(index)
	{
		for (var i = index; i < this.registeredBlocks.length; i++) 
		{
		//	console.log("Unregistering block in index " + i);
			this.registeredBlocks[i].unregisterAllContributors();
		}
		this.registeredBlocks.length = index;
	}


	pushBlockForShard(shardnum, openUnlocked)
	{
		var sameblockcount = 0;
		//console.log("Pushing block for shard " + shardnum);
		this.visibleBlockIds[this.pushedElements.length] = this.questionblockshards[shardnum].questionblockid;
		for (var i = 0; i < this.visibleBlockIds.length; i++) if (this.questionblockshards[shardnum].questionblockid == this.visibleBlockIds[i]) sameblockcount++;
		//console.log("Visible block id:s at the moment are: " + this.visibleBlockIds);
		if (openUnlocked)
		{
			if (this.questionblocksetSelectedOptions[this.questionblockshards[shardnum].questionblockid + "-" + sameblockcount] != null)
			{
				this.questionblocksetSelectedOptions[this.questionblockshards[shardnum].questionblockid + "-" + sameblockcount]["answerlocked"] = false;
				this.questionblocksetSelectedOptions[this.questionblockshards[shardnum].questionblockid + "-" + sameblockcount]["showdescription"] = false;
				this.questionblocksetSelectedOptions[this.questionblockshards[shardnum].questionblockid + "-" + sameblockcount]["isreselect"] = true;
			}
		}
		this.pushedElements.push(<QuestionBlock noderefs={this.nodeRefs} instructionsalwayson={this.instructionsalwayson} instructionsfirst={this.instructionsFirst} questionblocksetselectedoptions={this.questionblocksetSelectedOptions} mystacknumber={this.pushedElements.length} mysameblockcount={sameblockcount} notifyrerender={this} isstatic={this.isStatic} calculator={this.calculator} contentroot={this.contentroot} json={this.questionblockshards[shardnum]} commonstrings={this.commonstrings}/>);
	//	console.log(this.pushedElements);
	}


	previousLength = 0;

	render() {
		//console.log("Render block");
		//this.pushedElements = [];
		if (!this.wasRendered)
		{
			//console.log("Render QuestionBlockSet for the first time");
			this.wasRendered = true;
		}
		else
		{
			//console.log("Rerender QuestionBlockSet");
		}
		//Complicated rule because we keep the blocks if there are no new pushed elements. If there's a change to the amount of elements, we redraw.
		//console.log("Previous length was " + this.previousLength + " new length is " + this.pushedElements.length);
		if ((this.questionblocks.length == 0) || (this.previousLength != this.pushedElements.length))
		{
			//console.log("OK, redraw");
			this.questionblocks = [];
//			console.log("Rendering question blocks, length is " + this.questionblocks.length);
//			console.log("minimumToRenderTo is " + this.minimumToRenderTo);
//			console.log("pushedElements is " + this.pushedElements.length);
			var i = 0;
			var row = 0;
			//How long do we do two per rows?
			var maxi = 0;
			var fullset = 0;
			//maxi is one less if the last row has only one block.
			if (this.pushedElements.length > 0)
			{
				//console.log("B1");
				if (this.pushedElements.length%2 === 0) maxi = this.pushedElements.length;
				else maxi=this.pushedElements.length-1;
				fullset = this.pushedElements.length;
			}
			else
			{
				//console.log("B2");
				if (this.minimumToRenderTo%2 === 0) maxi = this.minimumToRenderTo;
				else maxi=this.minimumToRenderTo-1;
				fullset = this.minimumToRenderTo;
			}
//			console.log("Fullset is " + fullset + " twosets are " + maxi);
			if (!isMobile && !this.noColumns) for (i = 0; i < fullset;)
			{
				if (i >= this.pushedElements.length) {
					//console.log("Pushing elements that are not in pushed elements at all?! If this is a nonstatic view and this occurs after initial draw this is an error!");
					this.pushBlockForShard(i++, false);
				}
				else i++;
				//console.log("So, i is now " + i + " and maxi is " + maxi);
				if (i < maxi)
				{
					if (i >= this.pushedElements.length) {
						//console.log("Pushing elements that are not in pushed elements at all?! If this is a nonstatic view and this occurs after initial draw this is an error!");
						this.pushBlockForShard(i++, false);
					}
					else i++;
					//console.log("Two block: " + (i-2) + " and " + (i-1));
					var rowname = this.dapush + row++;
					//console.log("Rowname becomes " + rowname);
					this.questionblocks.push(<Row key={rowname}><Col span={12}>{this.pushedElements[i-2]}</Col><Col span={12}>{this.pushedElements[i-1]}</Col></Row>);
				}
				else 
				{
					//console.log("One block: " + (i-1));
					//WARNING: row++
					var rowname = this.dapush + row++;
					this.questionblocks.push(<Row key={rowname}><Col span={12}>{this.pushedElements[i-1]}</Col><Col span={12}><div> </div></Col></Row>);
				}
			}
			else for (i = 0; i < fullset; i++)
			{
				
				if (i >= this.pushedElements.length) this.pushBlockForShard(i,false);
				//console.log("Mobile block " + i);
				var rowname = this.dapush + row++;
				this.questionblocks.push(<Row key={rowname}><Col span={24}>{this.pushedElements[i]}</Col><Col span={24}><div> </div></Col></Row>);
//				this.questionblocks.push(<Row><Col><QuestionBlock  mystacknumber={currentStackNumber++} notifyrerender={this} calculator={this.calculator} contentroot={this.contentroot} json={this.questionblockshards[i]}/></Col></Row>);
			}
		}
		else
		{
			//console.log("Potential resolution?");
		}
		this.previousLengths = this.pushedElements.length;
		//console.log("Rendered set");

		if(this.checkOverallBlockStatus()) this.myhost.blockFullyDone(this.categoryid);
		else (this.myhost.blockUnderway(this.categoryid));
		return(<div>{this.questionblocks}</div>);
	}

	componentDidMount()
	{
	//	console.log("Set mounted, didmount all the visible blocks!");
		var i = 0;
		//for (i = 0; i < this.maximumToRenderTo; i++) this.questionblocks.componentDidMount();
	}
}

export default QuestionBlockSet;
