import React from 'react';
import ReactDOM from 'react-dom';


import 'antd/dist/antd.css';
import Input from './Input.js';
import { Radio, Checkbox } from 'antd';

const RadioGroup = Radio.Group;
class InputSelectList extends Input {

	controls = [];

	subtype="radio";
	direction="vertical";
	description="";

	controlfield=[];
	fieldwidth = 0;

	columns = [];
	rows = [];
	
	radioRows = null;

	fieldElementThatShouldBeDisabled = null;

	calculateAfterControlAdd = false;
	calculateSelectionsAfterControlAdd = [];
	calculateWeightsAfterControlAdd = [];

	constructor(props)
	{
		super(props);
		this.subtype = this.getV("subtype");
		this.direction = this.getV("direction");
		this.description = this.getV("description");
		this.columns = this.getV("columnlabels");
		this.currentlyChosenValues = props.currentlychosenvalues;
		
		//If the user hides the field without changing anything, these are needed :-(.
		this.calculateAfterControlAdd = false;
		for (var key in this.currentlyChosenValues)
		{
			this.calculateSelectionsAfterControlAdd.push(key);
			if (this.currentlyChosenValues[key]) this.calculateWeightsAfterControlAdd.push(1);
			else this.calculateWeightsAfterControlAdd.push(0);
			if (this.currentlyChosenValues[key])
			{
				this.calculateAfterControlAdd = this.calculateAfterControlAdd || true;
			}
		} 
		
	}

	currentlyChosenValues = [];
	
	somethingIsChosen()
	{
		for (var key in this.currentlyChosenValues)
		{
			if (this.currentlyChosenValues[key] != 0) return true;
		}
		return false;
	}

	getOptionsAsText()
	{
		var textToReturn = [];
		textToReturn.push(<div key="alltext">{this.commonstrings.nochoicemade}</div>);
		var chosen = [];
		for (var key in this.currentlyChosenValues)
		{
			if (this.currentlyChosenValues[key]) 
			{
				var currentChosen = ["", ""]
				var valuesplit = key.split("-");
				if ((this.subtype == "radio") || (this.subtype == "checkbox"))
				{
					currentChosen[0] = this.rows[valuesplit[1]];
				}
				else
				{
					currentChosen[0] = this.rows[valuesplit[0]];
					if ((this.columns != null) && (this.columns.length > 0))
					{
						currentChosen[1] = this.columns[valuesplit[1]];
					}
				}
				chosen.push(currentChosen);
			}
		}
		var tempRet = [];
		for (var i = 0; i < chosen.length; i++)
		{
			var temp = chosen[i][0];
			var keytemp = "mainlist"+i;
			if (chosen[i][1] != "") temp += " " + chosen[i][1]; 
			tempRet.push(<li key={keytemp}>{temp}</li>);
		}
		if (chosen.length > 0) 
		{
			textToReturn = [];
			textToReturn.push(<ul key="chosenul">{tempRet}</ul>);
		}
		return textToReturn;
	}


	renderControls()
	{
		if (this.columns != null) this.fieldwidth = this.columns.length;
		this.rows = [];
		switch (this.subtype)
		{
			case "radiofield":
			case "checkboxfield":
				var columnheaders = [];
				columnheaders.push(<th key="dummyth"></th>);
				var i = 0;
				for (i = 0; i < this.columns.length; i++)
				{
					columnheaders.push(<th key={"dummyths" + i}><div style={{fontSize:"12px", fontWeight:"normal", transform:"translate(15px, 30px) rotate(-45deg)", height:"60px", width:"30px"}} >{this.columns[i]}</div></th>);
				}
				var allrows = [];
				var populate = false;
				if (this.radioRows == null)
				{
					this.radioRows = [];
					populate = true;
				}
				for (i = 0; i < this.fields.length; i++)
				{
					var rowcontrols = this.processSet(i, this.fields[i].radios, this.fields[i].rowlabel, populate);
					allrows.push(<tr key={"dummytr" + i}><td key={"dummytd" + i}><div style={{padding: "3px", wordBreak: "break-all", wordWrap: "break-word"}}>{this.fields[i].rowlabel}</div></td>{rowcontrols}</tr>);
					this.rows.push(this.fields[i].rowlabel);
				}
					
				
				this.controlfield.push(<table key={"dummytable"}><thead key={"dummythead"}><tr key={"dummytablehtr"}>{columnheaders}</tr></thead><tbody>{allrows}</tbody></table>);
				break;
			default:
				this.controls = this.processSet(0, this.fields, null, false);
		}
		if (this.calculateAfterControlAdd) 
		{
			this.recalculateContributions(this.calculateSelectionsAfterControlAdd, this.calculateWeightsAfterControlAdd);
			this.calculateAfterControlAdd = false;
		}

	}

	processSet(row, array, rowlabel, populateRadioRows)
	{
		var ret = [];
		var populate = []
		if (populateRadioRows) this.radioRows.push(populate);
		for (var i = 0; i < array.length; i++)
		{
			var value = "" + row + "-" + i;
			var checked = false;
			if (this.currentlyChosenValues[value] != null) 
			{
				checked = (this.currentlyChosenValues[value] == 1);
				if (this.currentlyChosenValues[value] == null) console.log("Check your code: either a value is true or it is not there (null).");
			}
			if ( ( (this.subtype != "radiofield") ||
			       (this.subtype == "radiofield") && populateRadioRows) ||
			     ( (this.subtype != "checkboxfield") ||
			       (this.subtype == "checkboxfield") && populateRadioRows) )
			{
				this.registerMultiplierSetForSelection(value, array[i].multipliers, array[i].multipliercurves, array[i].decisions, array[i].destinations, (checked ? 1:0));
			}
			var trkey = "row"+i;
			switch (this.subtype)
			{
				case "radio":
					ret.push(<tr key={trkey}><td style={{padding: "0", margin: "0", verticalAlign: "top"}}><Radio onChange={this.valueChange} key={"radio-" + value} checked={checked} value={value} style={{padding: "3px", wordBreak: "break-all", wordWrap: "break-word"}}></Radio></td><td style={{padding: "1", margin: "0", verticalAlign: "top"}}>{array[i].label}</td></tr>);
					this.rows.push(array[i].label);
					break;
				case "radiofield":
					if (populateRadioRows) populate.push(false);
						ret.push(<td style={{padding: "0", margin: "0", verticalAlign: "middle"}} key={"radiotd-" + value}><Radio onChange={this.valueChange} checked={checked || this.radioRows[row][i]} name={"row"+row} key={"radio-" + value} value={value} /></td>);
					break;
				case "checkboxfield":
					if (populateRadioRows) populate.push(false);
					ret.push(<td  style={{padding: "0", margin: "0", verticalAlign: "middle"}} key={"radiotd-" + value}><Checkbox onChange={this.valueChange} checked={checked || this.radioRows[row][i]} key={"checkbox-" + value} value={value} /></td>);
					break;
				case "checkbox":
					ret.push(<tr><td style={{margin: "0", verticalAlign: "top"}}><div style={{paddingRight: "5px", paddingLeft: "10px"}}><Checkbox key={"checkbox-" + value} onChange={this.valueChange} checked={checked} value={value}></Checkbox></div></td><td style={{padding: "1", margin: "0", verticalAlign: "top"}}>{array[i].label}</td></tr>);
					this.rows.push(array[i].label);
					break;
				default: console.log("Unexpected subtype for selectlists : " + this.subtype);
			}
		}
		switch (this.subtype)
		{
			case "radio":
			case "checkbox":
				if (this.direction === "vertical") return(<table style={{borderCollapse: "collapse"}}><tbody>{ret}</tbody></table>);
			default:
		}
		return ret;
	}


	valueChange = (e) =>
	{
		//if (this.subtype === "radio") this.changelistener.pruneRedundantBlocks();
		var value = e.target.value;
		var valuesplit = value.split("-");

		var lastChosen = null;
		if (this.subtype === "radio") 
		{
			//This cannot be just cleared, because the reference counts!
			for (var curkey in this.currentlyChosenValues)
			{
				if (this.currentlyChosenValues[curkey] == 1) lastChosen = curkey;
				delete this.currentlyChosenValues[curkey];
			}
			while (this.currentlyChosenValues.length > 0) this.currentlyChosenValues.splice(0,1);
		}
		if (this.subtype === "radiofield") 
		{
			for (var i = 0; i < this.radioRows[valuesplit[0]].length; i++)
			{
				if (this.currentlyChosenValues[valuesplit[0] + "-" + i] == 1) 
				{
					lastChosen = valuesplit[0] +"-" + i;
				}
				delete this.currentlyChosenValues[valuesplit[0] +"-" + i];
				//this.currentlyChosenValues[valuesplit[0] + "-" + i] = null;
			}
		}
		this.currentlyChosenValues[value] = (e.target.checked ? 1: 0);
		//For checkboxes:
		if (!e.target.checked) delete this.currentlyChosenValues[values];

		var weight = 0;
		if (e.target.checked) weight = 1;


		if ((this.subtype === "radio") || (this.subtype === "radiofield"))
		{
			var values = [];
			var weights = [];

			values.push(value);
			weights.push(weight);

		
			//Each radiofield needs to be kept track of separately, so this array accumulates one entry per field located via first part of id
			//Note that this pushing is for the *calculations* (leftover values) whereas radioRows are for *UI*
			if (lastChosen != null)
			{
				values.push(lastChosen);
				weights.push(0);
			}

			if (this.subtype === "radiofield")
			{
				for (var i = 0; i < this.radioRows[valuesplit[0]].length; i++)
				{
					if (i == valuesplit[1])
					{
						this.radioRows[valuesplit[0]][i] = true;
					}
					else 
					{	
						this.radioRows[valuesplit[0]][i] = false;
					}
				}
			}

			this.recalculateContributions(values, weights);
			if (this.subtype === "radiofield")
			{
				this.controlfield = [];
			}
		}
		else 
		{
			var dummywrap1 = [value];
			var dummywrap2 = [weight];
			this.recalculateContributions(dummywrap1, dummywrap2);
		}
		if (this.subtype === "checkboxfield") this.controlfield = [];
		this.setState({});
		if (this.subtype === "radio") this.singleRadioAutoHide();
		
	}

	render() {
		switch(this.subtype)
		{
			case "radio":
				this.renderControls(); 
			//	return (
        		//		<RadioGroup onChange={this.valueChange}>{this.controls}</RadioGroup>
			//	);
				return this.controls;
			case "radiofield": 
				this.renderControls(); 
				return this.controlfield;
			case "checkboxfield": 
				this.renderControls(); 
				console.log("Checkbox field render");
				return this.controlfield;
			case "checkbox": 
				this.renderControls(); 
				return this.controls;
			default: console.log("Unexpected subtype for selectlists : " + this.subtype);
		}
	}
}

export default InputSelectList;
