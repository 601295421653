import React, { Component } from 'react';
import {mapValues, assign} from 'lodash';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import styled from 'styled-components'
import { FlowChart, onDragNode, onNodeClick, NodeWrapper, INodeWrapperProps, CanvasInnerDefault, ICanvasInnerDefaultProps, ICanvasOuterDefaultProps, FlowChartWithState, IChart, INodeInnerDefaultProps, Outer, Inner, PortDefaultOuter } from "@mrblenny/react-flow-chart";
import * as actions from "@mrblenny/react-flow-chart/src/container/actions";
import onPortPositionChange from "@mrblenny/react-flow-chart/src/container";
import FlowChartWithStateNodeClickOverride from './FlowChartWithStateNodeClickOverride.js'

class FullMap extends Component
{

	linkNumber = 1;
	chartState = null;
	chartWidths = [];

	layerHeight = 200;
	columnWidth = 250;
	columnPad = 10;
	rowTopPad = 10;

	myQuestionnaire = null;

	nodesInArray = [];
	categoriesInArray = [];
	usecategories = false;


	dumpSingleNode(node)
	{
		var toReturn = "";
		for (var k in node)
		{
			toReturn += "     " + k + " = " + node[k] + "\n";
		}
		return toReturn;
	}

	dumpNodesNoCycles(array)
	{
		var toReturn = "";
		for (var i = 0; i < array.length; i++)
		{
			toReturn += "" + i + " : " + this.dumpSingleNode(array[i]) + "\n";
		}
		return toReturn;
	}

	constructor(props) {
		super(props);

		var align = 0;
		var temp;
		var allNodes = props.questionblockshards;
		this.linkNumber = 1;

		this.layerHeight = props.layerheight;
		this.columnWidth = props.columnwidth;
		this.columnPad = props.columnpad;
		this.rowTopPad = props.rowtoppad;
		this.myQuestionnaire = props.questionnaire;
		this.myQuestionnaire.registerMap(this);
		this.usecategories = props.usecategories;
		var aligntext = props.align;
		if (aligntext == "center") align = 1;
		if (aligntext == "right") align = 2;

	

		var runid = 1;
		var runcatid = 1;


		for (var i = 0; i < allNodes.length; i++)
		{
			var newNode = {name: allNodes[i].headline, id: allNodes[i].questionblockid, destinationStrings : [], sources : [], destinations : [], chartId : "node"+runid++, portNumber : 1, depthLevel : -1, widthLevel : -1, currentColor : "", category : allNodes[i].category};
			if (allNodes[i].inputs != null)	for (var j = 0; j < allNodes[i].inputs.length; j++)
			{
				for (var k = 0; k < allNodes[i].inputs[j].fields.length; k++)
				{
					if (allNodes[i].inputs[j].fields[k].destinations != null)
					{
						for (var l = 0; l < allNodes[i].inputs[j].fields[k].destinations.length; l++)
						{
							newNode.destinationStrings[newNode.destinationStrings.length] = allNodes[i].inputs[j].fields[k].destinations[l][1];
						}
					}
				}
			}
			this.nodesInArray[this.nodesInArray.length] = newNode;
			if (newNode.category != null)
			{
				var newcat = true;	
				for (var e = 0; e < this.categoriesInArray.length; e++) if (this.categoriesInArray[e].id == newNode.category) { newcat = false; break; }
				if (newcat)
				{
					var newCategory = {isNode: true, destinationStrings: [], sources : [], destinations : [], chartId : "cat"+runcatid++, portNumber: 1, depthLevel : -1, widthLevel : -1, currentColor: "", id: newNode.category, name: newNode.category, firstNode: newNode, firstNodeInd: (this.nodesInArray.length-1)};
					for (var c = 0; c < props.categorynames.length; c++) 
						if (props.categorynames[c].catid == newCategory.id) { newCategory.name = props.categorynames[c].catlabel; break;}
					this.categoriesInArray[this.categoriesInArray.length] = newCategory;
				}
			}
		}
		//console.log("All categories before traversing: ");
		//console.log(this.dumpNodesNoCycles(this.categoriesInArray));
		var traversingRoot = true;
		for (var m = 0; m < this.nodesInArray.length; m++)
		{
			var sourceCat = this.nodesInArray[m].category;
			var destCat = [];
			if ((this.nodesInArray[m].destinationStrings.length == 0) && traversingRoot)
			{
				if (m+1 < this.nodesInArray.length) 
				{
					this.nodesInArray[m].destinations[0] = this.nodesInArray[m+1];
					destCat.push(this.nodesInArray[m+1].category);
				}
			}
			else
			{
				traversingRoot = false;
				for (var findS = 0; findS < this.nodesInArray[m].destinationStrings.length; findS++)
				{
					for (var n = 0; n < this.nodesInArray.length; n++)
					{
						if (this.nodesInArray[n].id == this.nodesInArray[m].destinationStrings[findS]) 
						{
							this.nodesInArray[m].destinations[findS] = this.nodesInArray[n];
							destCat.push(this.nodesInArray[n].category);
						}
					}
				}
			}
			if ((sourceCat != null) && (destCat.length > 0))
			{
				var sourceObject = null;
				var currentSourceNum = 0;
				for (var seekS = 0; seekS < this.categoriesInArray.length; seekS++) 
					if (this.categoriesInArray[seekS].id == sourceCat) { sourceObject = this.categoriesInArray[seekS]; break; }
				for (var p=0; p < destCat.length; p++)
				{
					var isRedundant = false;
					if (sourceCat == destCat[p]) isRedundant = true;
					for (var redu = 0; redu < p; redu++ ) if ( destCat[p] == destCat[redu] ) { isRedundant = true; break; }
					if (!isRedundant)
					{
						for (var seekD = 0; seekD < this.categoriesInArray.length; seekD++) 
							if (this.categoriesInArray[seekD].id == destCat) {
								sourceObject.destinations[currentSourceNum++] = this.categoriesInArray[seekD];
								break;
							}
					}
				}
			}
		}

		//console.log("All categories: ");
		//console.log(this.dumpNodesNoCycles(this.categoriesInArray));

		var traversedNodesTemp = [];

		//console.log("Making sure each category flows into the next one, even if the nodes do not cover that.");
		for (var m = 0; m < this.categoriesInArray.length; m++)
		{
			if (this.categoriesInArray[m].destinationStrings.length == 0)
			{
				if (m+1 < this.categoriesInArray.length) 
				{
					this.categoriesInArray[m].destinations[0] = this.categoriesInArray[m+1];
				}
			}
		}



		const chartStateTemp: IChart = {
 			offset: {
				x: 0,
				y: 0,
			},
			nodes: {},
			links: {},
			selected: {},
			hovered: {}
		};
		if (this.usecategories)
		{
			this.mapBuildRecursive(this.categoriesInArray[0], traversedNodesTemp, chartStateTemp, "");
		}
		else
		{
			this.mapBuildRecursive(this.nodesInArray[0], traversedNodesTemp, chartStateTemp, "");
		}
		if (align > 0)
		{
			var maxChartWidth = this.chartWidths[0];
			for (var i = 0 ; i < this.chartWidths.length; i++)
			{
				if (this.chartWidths[i] > maxChartWidth) maxChartWidth = this.chartWidths[i];
			}
			if (this.usecategories)
				this.mapFixCoordinates(align, maxChartWidth, this.categoriesInArray, chartStateTemp);
			else
				this.mapFixCoordinates(align, maxChartWidth, this.nodesInArray, chartStateTemp);
		}
	 	this.chartState = chartStateTemp;
		//console.log("Map constructed");
		//console.log(this.chartState);
	}

	mapFixCoordinates(mode, maxWidth, nodeArray, chartStateTemp)
	{
		var c = (mode == 1);
	
		var colWPad = this.columnPad + this.columnWidth;
		var maxRightX = colWPad * (maxWidth + 1);
		for (var i = 0; i < nodeArray.length; i++)
		{
			var cur = nodeArray[i];
			var curC = chartStateTemp.nodes[cur.chartId];
			var locx = cur.widthLevel;
			var locy = cur.depthLevel;
			if (curC != null)
			{
				if (c)
				{
					var emptySpaceLeft = (maxRightX - (colWPad * (this.chartWidths[locy] + 1))) / 2;
					curC.position.x = this.columnPad + emptySpaceLeft + (colWPad * locx);
				}
				else
				{
					var emptySpaceLeft = (maxRightX - (colWPad * (this.chartWidths[locy] + 1)));
					curC.position.x = this.columnPad + emptySpaceLeft + (colWPad * locx);
				}
			}
		}
	}

	mapBuildRecursive(currentNode, traversedNodes, chartState, indent)
	{
		var pos = {};
		var typ = "input-output";
		if (traversedNodes.length == 0)
		{
			currentNode.depthLevel = 0;
			currentNode.widthLevel = 0;
			this.chartWidths[0] = 0;
			pos = { x: this.columnPad, y: this.rowTopPad };
			typ = "output-only";
		}
		else
		{
			pos = { x: (this.columnPad + (currentNode.widthLevel * (this.columnWidth + this.columnPad))), 
				y: (this.rowTopPad + (currentNode.depthLevel * this.layerHeight)) };
		}
		var por = {};
		for (var n = 0; n < currentNode.destinations.length; n++)
		{
			por["port"+currentNode.portNumber] = { 	id: "port"+currentNode.portNumber++,
						type: "output",
						properties: { value: "yes" } };
		}
		if (por.port1 == null) typ = "input-only";
		chartState.nodes[currentNode.chartId] = {
			id: currentNode.chartId,
			type: typ,
			position: pos,
			ports: por,
			properties: {label: currentNode.name}
		};
		for (var i = 0; i < currentNode.destinations.length; i++)
		{
			var wasThere = false;
			if (traversedNodes.length != 0)
			{
				for (var j = 0; j < traversedNodes.length; j++)
				{
					if (traversedNodes[j].id == currentNode.destinations[i].id)
					{
						wasThere = true;
					}
				}
			}
			var destPort = -1;
			if (!wasThere)
			{
				traversedNodes[traversedNodes.length] = currentNode;
				currentNode.destinations[i].depthLevel = currentNode.depthLevel+1;
				if ( this.chartWidths[currentNode.depthLevel+1] == null) this.chartWidths[currentNode.depthLevel+1] = 0;
				else this.chartWidths[currentNode.depthLevel+1]++;
				currentNode.destinations[i].widthLevel = this.chartWidths[currentNode.depthLevel+1];
				this.mapBuildRecursive(currentNode.destinations[i], traversedNodes, chartState, indent+"-------");
			}
			var dest = currentNode.destinations[i];
			chartState.nodes[dest.chartId].ports["port"+dest.portNumber] = { 	
								id: "port"+dest.portNumber++,
								type: "input",
								properties: { value: "yes" } 
							};
			var newLink = { id: "link" + this.linkNumber,
					from: {
						nodeId: currentNode.chartId,
						portId: "port"+(i+1)
					},
					to: {
						nodeId: dest.chartId,
						portId: "port"+(dest.portNumber-1)
					}
				}
			chartState.links["link" + this.linkNumber++] = newLink;
			dest.sources.push(currentNode);
		}
		if (currentNode.destinations.length == 0)
		{
				traversedNodes[traversedNodes.length] = currentNode;
		}
	}

	colorMap = [];

	nodeClicked(n)
	{
		var nodesToUse = this.nodesInArray;
		if (this.usecategories) nodesToUse = this.categoriesInArray;
		for (var i = 0; i < nodesToUse.length; i++)
		{
			if ( nodesToUse[i].chartId == n)
			{
				if (this.useoptionpaths)
				{
					var optionpath = this.setActiveNode(nodesToUse[i]);
					this.myQuestionnaire.setOptionpath(optionpath);
				}
				else
				{
					this.setActiveNode(nodesToUse[i], false);
					this.myQuestionnaire.jumpToBlock(nodesToUse[i].id, this.useCategories);
				}
				this.setState({});
				break;
			}
		}
		
	}

	setActiveNode(n, paintpath)
	{
		/*
		for (var key in this.colorMap)
		{
			this.colorMap[key] = "cornflowerblue";
		}
		var optionpath = null;
		if (paintpath) optionpath = this.tagColorRecursive(n, false);
		this.colorMap[n.id] = "red";
		return optionpath;
		*/
		this.myQuestionnaire.jumpToBlock(n.id, this.usecategories);
	}

	setClearedNode(id)
	{
		var nodesToUse = this.nodesInArray;
		if (this.usecategories) nodesToUse = this.categoriesInArray;
		this.colorMap[id] = "green";
		this.setState({});
	}
	
	unSetClearedNode(id)
	{
		var nodesToUse = this.nodesInArray;
		if (this.usecategories) nodesToUse = this.categoriesInArray;
		this.colorMap[id] = "cornflowerblue";
		this.setState({});
	}

	tagColorRecursive(n, altNode)
	{
		var optionpath = null;
		if (altNode) this.colorMap[n.id] = "gray";
		else this.colorMap[n.id] = "green";
		var nodesToUse = this.nodesInArray;
		if (this.usecategories) nodesToUse = this.categoriesInArray;
		if (n == nodesToUse[0]) return;
		for (var i = 0; i < n.sources.length; i++)
		{
			if (i == 0) optionpath = this.tagColorRecursive(n.sources[i], false);
			else this.tagColorRecursive(n.sources[i], true);
		}
		var toReturn = [];
		toReturn.push(n.id);
		if (optionpath != null) for (var j = 0; j < optionpath.length; j++)
		{
			toReturn.push(optionpath[j]);
		}
		return toReturn;
	}
	

	render()
	{
		const RoundedgeBox = styled.div`
			position: absolute;
			padding: 5px;
			background: white;
			color: black;
			display: block;
			border: solid;
			align-items: center;
			border-radius: 5px;
			border-width: 3px;
			border-color: ${props => props.curColor};
			justify-content: center;
			width: ${props => props.curWidth};
		`
		const CanvasOuterCustom = styled.div`
			position: relative;
			background-size: 10px 10px;
			background-color: white;
			width: 100%;
			height: 100%;
			overflow: hidden;
			cursor: not-allowed;
		` 

		const CanvasCustom = ( { canvas, children, ...otherProps } : ICanvasDefaultProps) => {
				return (
					<CanvasOuterCustom {...otherProps}>
						{children}
					</CanvasOuterCustom>
				)
		}

		const NodeCustom = ({ node, children, onClick, ...otherProps }: INodeDefaultProps) => {
				//console.log("Starting node custom");
				var myWidth="" + this.columnWidth + "px";
				var curcol = "cornflowerblue";
				var nodesToUse = this.nodesInArray;
				if (this.usecategories) nodesToUse = this.categoriesInArray;
				for (var j = 0; j < nodesToUse.length; j++)
				{
					if ( nodesToUse[j].chartId == node.id)
					{
						if (this.colorMap[nodesToUse[j].id] == null) this.colorMap[nodesToUse[j].id] = "cornflowerblue";
						curcol = this.colorMap[nodesToUse[j].id];
					//	console.log("Draw node " + this.nodesInArray[j].id + " with color " + curcol);
						break;
					}
				}
				
				//console.log("Got through until this point");
				//for (var key in otherProps) console.log(key + " is " + otherProps[key]);
				return (
					<RoundedgeBox curColor={curcol} onClick={onClick} curWidth={myWidth} {...otherProps}>
						{children}
					</RoundedgeBox>
				)
		}

		const NodeInnerCustom = ({ node }: INodeInnerDefaultProps) => {
		    return (<div align="center">{node.properties.label}</div>);
		}
	

		const CanvasInnerNoBorder = styled(CanvasInnerDefault)`
			outline: none; 
		`;

		const PortCustom = ({ port }: IPortDefaultProps) => {
			return (<div></div>
			);
		}
		const generateCurvePath = (startPos: IPosition, endPos: IPosition): string => {
			const width = Math.abs(startPos.x - endPos.x)
			const height = Math.abs(startPos.y - endPos.y)
			const leftToRight = startPos.x < endPos.x
			const topToBottom = startPos.y < endPos.y
			const isHorizontal = false

			let start
			let end
			if (isHorizontal) {
				start = leftToRight ? startPos : endPos
				end = leftToRight ? endPos : startPos
			} else {
				start = topToBottom ? startPos : endPos
				end = topToBottom ? endPos : startPos
			}

			const curve = isHorizontal ? width / 3 : height / 3
			const curveX = isHorizontal ? curve : 0
			const curveY = isHorizontal ? 0 : curve

			return `M${start.x},${start.y} C ${start.x + curveX},${start.y + curveY} ${end.x - curveX},${end.y - curveY} ${end.x},${end.y}`
		}


		const LinkCustom = ({
				link,
				startPos,
				endPos,
				onLinkMouseEnter,
				onLinkMouseLeave,
				onLinkClick,
				isHovered,
				isSelected,
		}: ILinkDefaultProps) => {
			const points = generateCurvePath(startPos, endPos)
			return (
				<svg style={{ overflow: 'visible', position: 'absolute', cursor: 'pointer', left: 0, right: 0 }}>
					<path
						d={points}
						stroke="cornflowerblue"
						strokeWidth="3"
						fill="none"
					/>
					<path
						d={points}
						stroke="blue"
						strokeWidth="20"
						fill="none"
						strokeLinecap="round"
						strokeOpacity={(isHovered || isSelected) ? 0.1 : 0}
					/>
					<polygon
						points={(endPos.x-10)+","+(endPos.y-10)+" "+(endPos.x+10)+","+(endPos.y-10)+" "+(endPos.x)+","+(endPos.y+10)}
						className="triangle"
						fill="cornflowerblue"
					/>
				</svg>
			)
		}


		return(<div><FlowChartWithStateNodeClickOverride
			mymap={this}
			chart={this.chartState}
			initialValue={this.chartState} 
			Components={{
				Node: NodeCustom, 
				Link: LinkCustom, 
				NodeInner: NodeInnerCustom, 
				Port: PortCustom, 
				Canvas: CanvasCustom, 
				CanvasOuter: CanvasOuterCustom, 
				CanvasInner: CanvasInnerNoBorder
			}} /></div>);
	}
}

export default FullMap;

