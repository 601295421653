import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import InputButtonDropdown from './inputs/InputButtonDropdown.js';
import InputPlainDropdown from './inputs/InputPlainDropdown.js';
import InputSelectList from './inputs/InputSelectList.js';
import InputSlider from './inputs/InputSlider.js';
import InputXYMap from './inputs/InputXYMap.js';
import JSONBlockSource from './inputs/JSONBlockSource.js'
import 'antd/dist/antd.css';
import { Card, Row, Col, Collapse, Spin, Anchor, Table } from 'antd';
import { isMobile } from "react-device-detect";
import Markdown from "react-markdown";


const Link = Anchor.Link;


class QuestionBlock extends Component implements JSONBlockSource
{

	questionblockid="";
	headline="";
	instructionsinline="";
	instructions="";
	instructionsContent="";
	inputs = [];
	inputTypes = [];
	contentroot = "";
	rerenderListener = null;
	mystacknumber = 0;
	myInputs = null;
	myInputRefs = [];
	isStatic = true;

	showSpinner = -1;
	showDescription = true;


	calculator = null;
	
	generalContent = "";
	generalRend = [];

	questionblocksetSelectedOptions = null;
	myoptionsid = "";

	commonstrings=null;

	handleMarkdownResponse(response)
	{
		this.generalContent = response;
		this.generalRend.push(<Markdown key="gencontent" escapeHtml={false} transformImageUri={this.transformimguri} source={this.generalContent}/>);
		if (this.showSpinner === 1)
		{
			this.showSpinner = 0;
			this.setState({});
			this.componentDidMount();
		}
		this.showSpinner = 0;
	}

	instructionsFirst = false;
	instructionsalwayson = false;
	mysameblockcount = 0;

	constructor(props)
	{
		super(props);
		this.commonstrings = props.commonstrings;
		this.questionblockid = props.json.questionblockid;
		this.contentroot = props.contentroot;
		this.headline = props.json.headline;
		this.instructionsFirst = props.instructionsfirst;
		if (props.json.instructionsfirst == "true") this.instructionsFirst = true;
		if (props.json.instructionsfirst == "false") this.instructionsFirst = false;
		this.instructionsinline = props.json.instructionsinline;
		this.instructionsalwayson = props.instructionsalwayson;
		if (props.json.instructionsalwayson == "true") this.instructionsalwayson = props.json.instructionsalwayson;
		this.instructions = props.json.instructions;
		this.inputs = props.json.inputs;
		this.calculator = props.calculator;
		this.rerenderListener = props.notifyrerender;
		this.mystacknumber = props.mystacknumber;
		this.isStatic = props.isstatic;
		this.questionblocksetSelectedOptions = props.questionblocksetselectedoptions;
		this.mysameblockcount = props.mysameblockcount;
		this.myoptionsid = this.questionblockid + "-" + this.mysameblockcount;
		//console.log("Using optionsid " + this.myoptionsid);
		this.nodeRefs = props.noderefs;
		this.nodeRefs["node-"+this.questionblockid] = React.createRef();
		if (this.questionblocksetSelectedOptions[this.myoptionsid] != null)
		{
			//console.log(this.questionblocksetSelectedOptions[this.myoptionsid]);
			if (this.questionblocksetSelectedOptions[this.myoptionsid]["answerlocked"] != null)
				this.answerLocked = this.questionblocksetSelectedOptions[this.myoptionsid]["answerlocked"];
				else this.answerLocked = false;
			//if (this.answerLocked) console.log("   I am locked");
			if (this.questionblocksetSelectedOptions[this.myoptionsid]["showdescription"] != null)
				this.showDescription = this.questionblocksetSelectedOptions[this.myoptionsid]["showdescription"];
				else this.showDescription = false;
			//if (this.answerLocked) console.log("   I show description");
			if (this.questionblocksetSelectedOptions[this.myoptionsid]["selectedastext"] != null)
				this.selectedOptionsText = this.questionblocksetSelectedOptions[this.myoptionsid]["selectedastext"];
			//if (this.selectedOptionsText.length > 0) console.log("   I have description");
		}
			
		var markdownhandler = this;

		if ((this.instructions != null) && (this.instructions != "")) 
		{
				fetch(this.props.contentroot+this.instructions).then(
				function(response)
				{
					return response.text();
				}
			).then(
				function(text)
				{
					markdownhandler.handleMarkdownResponse(text);
				});
		}
		else
		{
			this.showSpinner = 0;
		}


	}

	readBlock()
	{
		//console.log("Read question block JSON for export");
	}	

	registerInputEntry(index, entry)
	{
		if (this.myInputRefs[index] != null) this.calculator.unregisterContributor(this.myInputRefs[index]);
		this.myInputRefs[index] = entry;
		//TODO: Verify that unregistering here is sufficient for calculator!
	}

	unregisterAllContributors()
	{
		for (var i = 0; i < this.myInputRefs.length; i++)
		{
			if (this.myInputRefs[i] != null) this.calculator.unregisterContributor(this.myInputRefs[i]);
		}
	}

	addInputs()
	{
		this.myInputRefs = [];
		var ret = [];
		if (this.inputs == null) return ret;
		for (var i = 0; i < this.inputs.length; i++)
		{
			
			if (this.questionblocksetSelectedOptions[this.myoptionsid] == null) this.questionblocksetSelectedOptions[this.myoptionsid] = [];
			if (this.questionblocksetSelectedOptions[this.myoptionsid]["input"+i] == null) this.questionblocksetSelectedOptions[this.myoptionsid]["input"+i] = [];
			switch (this.inputs[i].type)
			{
				case "selectlist": ret.push(<InputSelectList commonstrings={this.commonstrings} currentlychosenvalues={this.questionblocksetSelectedOptions[this.myoptionsid]["input"+i]} inputrefs={this.myInputRefs} inputindex={i} changelistener={this} calculator={this.calculator} key={this.questionblockid+this.inputs[i].type+i} contentroot={this.contentroot} json={this.inputs[i]} />); break;
				case "plaindropdown": ret.push(<InputPlainDropdown commonstrings={this.commonstrings} currentlychosenvalues={this.questionblocksetSelectedOptions[this.mytoptionsid]["input"+i]} inputrefs={this.myInputRefs} inputindex={i} changelistener={this} calculator={this.calculator} key={this.questionblockid+this.inputs[i].type+i} contentroot={this.contentroot} json={this.inputs[i]}/>); break;
				case "buttondropdown": ret.push(<InputButtonDropdown commonstrings={this.commonstrings} currentlychosenvalues={this.questionblocksetSelectedOptions[this.mytoptionsid]["input"+i]} inputrefs={this.myInputRefs} inputindex={i} changelistener={this} calculator={this.calculator} key={this.questionblockid+this.inputs[i].type+i} contentroot={this.contentroot} json={this.inputs[i]}/>); break;
				case "slider": ret.push(<InputSlider commonstrings={this.commonstrings} currentlychosenvalues={this.questionblocksetSelectedOptions[this.myoptionsid]["input"+i]} inputrefs={this.myInputRefs} inputindex={i} changelistener={this} calculator={this.calculator} key={this.questionblockid+this.inputs[i].type+i} contentroot={this.contentroot} json={this.inputs[i]}/>); break;
				case "xymap": ret.push(<InputXYMap commonstrings={this.commonstrings} currentlychosenvalues={this.questionblocksetSelectedOptions[this.myoptionsid]["input"+i]} inputrefs={this.myInputRefs} inputindex={i} changelistener={this} calculator={this.calculator} key={this.questionblockid+this.inputs[i].type+i} contentroot={this.contentroot} json={this.inputs[i]}/>); break;
				default: console.log("Unknown input type: " + this.inputs[i].type);
			}
		}
		return ret;
	}

	changed()
	{
		//console.log("Should we autolock?");
	}	

	answerLocked = false;
	selectedOptionsText = [];

	calculateDestinationAndPopulateOptionsset()
	{
		var allDestinations = [];
		var optionsChosen = false;
		for (var i = 0; i < this.myInputRefs.length; i++)
		{
			
			this.selectedOptionsText[i] = this.myInputRefs[i].getOptionsAsText();
			optionsChosen = optionsChosen || this.myInputRefs[i].somethingIsChosen();
			
			var currentDestinations = this.myInputRefs[i].getListOfDestinations();
			//console.log("DESTINATIONS: " + currentDestinations.length + " : " + currentDestinations);
			for (var j = 0; j < currentDestinations.length; j++ )
			{
				if (allDestinations[currentDestinations[j]] != null)
				{
					allDestinations[currentDestinations[j]] += this.myInputRefs[i].getContributionForDestination(currentDestinations[j]);
			//		console.log("Add to " + j + " weight " + allDestinations[currentDestinations[j]]);
				}
				else
				{
					allDestinations[currentDestinations[j]] = this.myInputRefs[i].getContributionForDestination(currentDestinations[j]);
			//		console.log("Create " + j + " weight " + allDestinations[currentDestinations[j]]);
				}
			}
		}
		var currentMax = -1;
		var currentDestinations = [];
		//console.log("All destinations for " + this.questionblockid + " are " + allDestinations);
		for (var key in allDestinations)
		{
			//console.log("ALL DESTINATIONS " + key + " : " + allDestinations[key]);
			if (allDestinations[key] == currentMax) 
			{
				currentDestinations.push(key);
				//console.log("WARNING: Same score for two destinations one of which is " + key + " in conditional rules!");
			}
			if ((allDestinations[key] > currentMax) && (allDestinations[key] > 0))
			{
				currentDestinations = [];
				currentDestinations.push(key);
				currentMax = allDestinations[key];
			}
		}
		if ( currentDestinations.length > 0 )
		{
			this.rerenderListener.blockSelectionDone(this.questionblockid);
			this.setState({});
			//console.log("Called this for " + currentDestinations + " in " + this.questionblockid);
			this.rerenderListener.addBlocksAndRerender(currentDestinations, true);
			return true;
		}
		if ( (allDestinations.length == 0) && optionsChosen )
		{
			this.rerenderListener.blockSelectionDone(this.questionblockid);
			this.rerenderListener.startRenderingDeductions(true);
		}
		else return false;
		
	}

	collapseStatusChange = (key) => {
		this.showDescription = !this.showDescription;
		if (this.questionblocksetSelectedOptions[this.myoptionsid] == null) this.questionblocksetSelectedOptions[this.mytoptionsid] = [];
		this.questionblocksetSelectedOptions[this.myoptionsid]["showdescription"] = this.showDescription;
	}

	inputValueChanged()
	{
		this.currentValueChanged = true;
	}

	currentValueChanged = false;

	handleAnchorClick = (e, link) => {
		if (e != null) e.preventDefault();
		if (link.href == "lock")
		{
			this.pruneRedundantBlocks();
			this.answerLocked = true;
			if (this.questionblocksetSelectedOptions[this.myoptionsid] == null) this.questionblocksetSelectedOptions[this.myoptionsid] = [];
			this.questionblocksetSelectedOptions[this.myoptionsid]["answerlocked"] = this.answerLocked;
			if (this.currentValueChanged || this.questionblocksetSelectedOptions[this.myoptionsid]["isreselect"])
			{
				//console.log("Reselect done");
				this.questionblocksetSelectedOptions[this.myoptionsid]["isreselect"] = false;
				if (this.calculateDestinationAndPopulateOptionsset())
				{
					//console.log("B1");
					this.questionblocksetSelectedOptions[this.myoptionsid]["selectedastext"] = this.selectedOptionsText;
					return;
				}
				else
				{
					//console.log("B2");
					//TODO: Do we prune here?!
					this.pruneRedundantBlocks();
					this.questionblocksetSelectedOptions[this.myoptionsid]["selectedastext"] = this.selectedOptionsText;
				}
			}
		}
		else
		{
			this.rerenderListener.startRenderingDeductions(false);
			this.answerLocked = false;
			if (this.questionblocksetSelectedOptions[this.myoptionsid] == null) this.questionblocksetSelectedOptions[this.myoptionsid] = [];
			this.questionblocksetSelectedOptions[this.myoptionsid]["answerlocked"] = this.answerLocked;
		}
		this.setState({});
	}

	pruneRedundantBlocks()
	{
		this.inputValueChanged();
		if (!this.isStatic) this.rerenderListener.rerender(this.mystacknumber);
	}


	renderWithInstructions() {
		var anchor = [];
		var reselect = [];
		var savesetting = [];

		reselect.push(<Markdown key="reselect" escapeHtml={false} transformImageUri={this.transformimguri} source={this.commonstrings.reselect}/>);
		savesetting.push(<Markdown key="savesetting" escapeHtml={false} transformImageUri={this.transformimguri} source={this.commonstrings.savesetting}/>);
		if (this.answerLocked)
		{
			anchor.push(<Anchor key="clickanchorunlock" affix={false} onClick={this.handleAnchorClick}><Link key="clicklinklock" href="unlock" title={reselect} /></Anchor>);
		}
		else
		{
//			console.log("Render as unlocked");

			anchor.push(<Anchor key="clickanchorlock" affix={false} onClick={this.handleAnchorClick}><Link key="clicklinkunlock" href="lock" title={savesetting} /></Anchor>);
		}

		if (this.answerLocked)
		{
				var mdheadline = [];
				mdheadline.push(<Markdown key="lockmsg" escapeHtml={false} transformImageUri={this.transformimguri} source={this.headline}/>);
				var selectedAsText = [];
				//console.log("Selected length is : " + this.selectedOptionsText.length);
				//selectedAsText.push(mdheadline);
				var dataSource = [];
				dataSource.push({key:"init0", content:mdheadline, content2:anchor});
				
				for (var r = 0; r < this.selectedOptionsText.length; r++) { 
					//var selepara = "selectedinpara" + r;
					//if (r > 0) selectedAsText.push(<p key={selepara}> &nbsp; </p>);
					dataSource.push({key:""+r, content:this.selectedOptionsText[r], content2:"none"});
					//selectedAsText.push(this.selectedOptionsText[r]); 
				}
				//var mdheadline = [];
				//mdheadline.push(<Markdown key="instructionmd" escapeHtml={false} transformImageUri={this.transformimguri} source={this.headline}/>);
				var dataSourceContent=[];
				dataSourceContent.push(	
					<Row key="lockrow" type="flex" justify="space-between">
					<Col key="lockcol1" align="left" flex="auto">
					<div key="lockcoldiv1" className="ant-card-head">
					<div key="lockcoldiv2" className="ant-card-head-title">
					{mdheadline}
					</div>
					</div>
					{selectedAsText}
					</Col>
					<Col key="lockcol2" align="right" flex="auto">
					{anchor}
					</Col>
					</Row>
				);
				//var dataSource = [{key:"1", content:{dataSourceContent}}];
				
				var columns=[{title: "none", dataIndex: "content", key: "none", 
					render: (text, row, index) => {
					      if (index < 1) {
					        return { children: <div style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500", fontSize: "16px"}}>{text}</div>,
							 props: {
							 className: "ruoritable"
							}
						};
					      }
					      return { children: <div>{text}</div>,
					        props: {
					          colSpan: 2,
							 className: "ruoritable"
					        },
					      };
  					}}, 
					{title:"none2", dataIndex: "content2", key: "none2",
					render: (text, row, index) => {
					      if (index < 1) {
					        return { children: <div  style={{ wordWrap: 'break-word', wordBreak: 'break-word' }} className="ant-card-extra" >{text}</div>,
							 props: {
								align: "right",
							 className: "ruoritable"
							}
						};
					      }
					      return {
					        props: {
					          colSpan: 0,
							 className: "ruoritable"
					        },
					      };
  					}}
				];
				return (
					<Table tableLayout="auto" size="small" pagination={false} bordered={false} showHeader={false} columns={columns} dataSource={dataSource}
					onRow={(record, rowIndex) => {
   						 return {
 						     onClick: e => {e.preventDefault();e.stopPropagation();e.nativeEvent.stopImmediatePropagation(); return;},
  						    onDoubleClick: e => {e.preventDefault();e.stopPropagation();e.nativeEvent.stopImmediatePropagation();return;}, 
  						    onContextMenu: e => {e.preventDefault();e.stopPropagation();e.nativeEvent.stopImmediatePropagation();return;}, 
   						   onMouseEnter: e => {e.preventDefault();e.stopPropagation();e.nativeEvent.stopImmediatePropagation();return;}, 
  						    onMouseLeave: e => {e.preventDefault();e.stopPropagation();e.nativeEvent.stopImmediatePropagation();return;} 
 						  };
 					 }}
					/>
					
				);
/*
					<Card 
						title={selectedAsText}
						key="lockedcard" 
						extra={anchor}
						style={{ margin:'1%'}} 
					>
					</Card>
*/
		}
		else
		{
			//console.log("My stack number is " + this.mystacknumber);
			this.rerenderListener.registerBlockForIndex(this, this.mystacknumber);
			if (!isMobile)
			{
				return this.buildCommonCardHeader(isMobile, this.instructionsFirst, anchor);
			}
			else
			{
				return this.buildCommonCardHeader(isMobile, this.instructionsFirst, anchor);
			}
		}

	}

	testRow(a, b)
	{
		var toReturn = [];
		toReturn.push(a);
		console.log(a.children);
		console.log(a.children[0]);
		console.log(a.children[0].props);
		console.log(a.children[0].props.column);
		toReturn.push(a.children[0].props.column.render());
		return toReturn;
		
	}

	nullFunction(e)
	{
		alert(e);
	}

	buildCardContent(mob, instfirst, anchor)
	{
		var inlineinst = [];
		inlineinst.push(<Markdown key="instructionsmd" escapeHtml={false} transformImageUri={this.transformimguri} source={this.instructionsinline}/>);
		var actkey = []
		if (this.showDescription) actkey = ["desc"];
		if (this.myInputs.length == 0)
		{
			if (mob)
			{
				if (!this.instructionsalwayson)
				{
					return(
						<Collapse defaultActiveKey={actkey} onChange={this.collapseStatusChange}>
						<Collapse.Panel header={this.commonstrings.defaultdesc} key="desc">
						{this.generalRend}
						{inlineinst}
						</Collapse.Panel>
						</Collapse>
					);
				}
			}
			return(
				<div key="notmobilediv">
					{this.generalRend}
					{inlineinst}
				</div>
			);
		}
		if (mob)
		{
			if (this.instructionsalwayson)
			{
				return(
					<div>
					<Row>
					<Col span={24}>
					{this.generalRend}
					{inlineinst}
					</Col>
					</Row>
					<Row>
					<Col span={24}>
					{this.myInputs}
					</Col>
					</Row>
					</div>
				);
			}
			else
			{
				return(
					<div>
					<Row>
					<Col span={24}>
					<Collapse defaultActiveKey={actkey} onChange={this.collapseStatusChange}>
					<Collapse.Panel header={this.commonstrings.defaultdesc} key="desc">
					{this.generalRend}
					{inlineinst}
					</Collapse.Panel>
					</Collapse>
					</Col>
					</Row>
					<Row>
					<Col span={24}>
					{this.myInputs}
					</Col>
					</Row>
					</div>
				);
			}
		}
		if (!instfirst)
		{
			return(
				<Row>
				<Col span={12}>
				{this.myInputs}
				</Col>
				<Col span={12}>
				{this.generalRend}
				{inlineinst}
				</Col>
				</Row>
			);
		}
		else
		{
			return(
				<Row>
				<Col span={12}>
				{this.generalRend}
				{inlineinst}
				</Col>
				<Col span={12}>
				{this.myInputs}
				</Col>
				</Row>
			);
		}
	}


	buildCommonCardHeader(mob, instfirst, anchor)
	{
		var mdheadline = [];
		mdheadline.push(<Markdown key="instructionmd" escapeHtml={false} transformImageUri={this.transformimguri} source={this.headline}/>);
		if (this.myInputs.length == 0)
		{
			return(
				<Card
				      title={mdheadline}
				      style={{ margin:'1%'}}
				>
				{this.buildCardContent(mob, instfirst, anchor)}
				</Card>
			);
		}
		else
		{
			return(
				<Card
				      title={mdheadline}
				      extra={anchor}
				      style={{ margin:'1%'}}
				>
				{this.buildCardContent(mob, instfirst, anchor)}
				</Card>
			);
		}
	}

	render() {

		if ((this.showSpinner === -1) || (this.showSpinner === 1))
		{
			return <div><div ref="blockcontainer1" /></div>;
		}
		else
		{
			return <div><div ref="blockcontainer2" /></div>;
		}

	}

	componentDidUpdate()
	{
		this.componentDidMount();
	}

	componentDidMount()
	{
		if ((this.showSpinner === -1) || (this.showSpinner === 1))
		{
			this.showSpinner = 1;
			ReactDOM.render(<div key="mainblockspin"> <Spin size="large" /></div>, this.refs.blockcontainer1);

		}
		else
		{
			if (this.myInputs == null) 
			{
				this.myInputs = this.addInputs();
			}
			var block = this.renderWithInstructions();
			ReactDOM.render(<div key="mainblockdiv" ref={this.nodeRefs["node-"+this.questionblockid]}>{block}</div>, this.refs.blockcontainer2);
		}
	}


}

export default QuestionBlock;
