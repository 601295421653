class Calculator {

	contributors = [];
	changeListener = null;

	generatedDecisions = [];
	generatedDecisionSums = [];

	registerContributor(contributor)
	{
	//	console.log("New contributor registered: " + contributor);
		this.contributors.push(contributor);
		//console.log("My contributors after adding one: ");
		//console.log(this.contributors);
	}
	
	unregisterContributor(contributor)
	{
	//	console.log("Contributor unregistered: " + contributor);
		for (var i = 0; i < this.contributors.length; i++)
		{
			if (this.contributors[i] === contributor)
			{
				delete this.contributors[i];
	//			console.log("Found and deleted");
				this.contributors.splice(i,1);
	//			console.log("Spliced");
			}
		}
		//console.log("My contributors after removing one: ");
		//console.log(this.contributors);
	}

	registerChangeListener(listener)
	{
		this.changeListener = listener;
	}

	changed()
	{
		if (this.changeListener != null) this.changeListener.updateDisplayedCalculations();	
	}

	getSum(type, decision)
	{
		var sum = 0;
		for (var i = 0; i < this.contributors.length; i++)
		{
			switch (type)
			{
				case 0:	sum += this.contributors[i].getMinTimeContribution(); break;
				case 1: sum += this.contributors[i].getMaxTimeContribution(); break;
				case 2: sum += this.contributors[i].getMinCostContribution(); break;
				case 3: sum += this.contributors[i].getMaxCostContribution(); break;
				case 4:	sum += this.contributors[i].getMinMonthlyTimeContribution(); break;
				case 5: sum += this.contributors[i].getMaxMonthlyTimeContribution(); break;
				case 6: sum += this.contributors[i].getMinMonthlyCostContribution(); break;
				case 7: sum += this.contributors[i].getMaxMonthlyCostContribution(); break;
				case 8: sum += this.contributors[i].getContributionForDecision(decision); break;
				default: return -1;
			}
			//console.log("Sum after contributor " + i + " is " + sum);
		}
		return sum;
	}


	getSumOfMinTimes()
	{
		return this.getSum(0);
	}

	getSumOfMaxTimes()
	{
		return this.getSum(1);
	}

	getSumOfMinCosts()
	{
		return this.getSum(2);
	}
	
	getSumOfMaxCosts()
	{
		return this.getSum(3);
	}

	getSumOfDecisionContribution(decision)
	{
		return this.getSum(8, decision);
	}

	getSumOfMinMonthlyTimes()
	{
		return this.getSum(4);
	}

	getSumOfMaxMonthlyTimes()
	{
		return this.getSum(5);
	}

	getSumOfMinMonthlyCosts()
	{
		return this.getSum(6);
	}
	
	getSumOfMaxMonthlyCosts()
	{
		return this.getSum(7);
	}
	getGreatestDecision(decisionSet, includeAllAboveZeroIfMultiple)
	{
		this.generatedDecisions = [];
		//console.log("Pick from set: ");
		//console.log(decisionSet);
		var allDecisionSums = [];
		var greatestSoFar = -1;
		var severalWithSame = false;
		var greatestSum = -1;
		var greatestMultiplicationName = null;
		var greatestMultiplication = 0;
		for (var i = 0; i < decisionSet.length; i++)
		{
			var compareGenerated = [];
			if (decisionSet[i].includes(",")) compareGenerated = decisionSet[i].split(",");
			allDecisionSums[i] = this.getSumOfDecisionContribution(decisionSet[i]);
			//console.log("Get sum for decision " + decisionSet[i]);
			if ((allDecisionSums[i] > 0) || (compareGenerated.length > 0))
			{
				if (!(compareGenerated.length > 0))
				{
					//console.log("Not comma separated, pushing decision");
					this.generatedDecisions.push(decisionSet[i]);
					this.generatedDecisionSums.push(allDecisionSums[i]);
				}
				if ((compareGenerated.length > 0) &&
					(compareGenerated[compareGenerated.length - 1] == this.generatedDecisions[this.generatedDecisions.length - 1]))
				{
					//console.log("Started comparisons");
					var multiply = 1;
					var stepBack = 0;
					var k = -2;
					for (k = compareGenerated.length - 1; k >= 0; k--)
					{
						//console.log("Compare " + this.generatedDecisions[this.generatedDecisions.length - 1 - stepBack] + " to " +
							//compareGenerated[compareGenerated.length - 1 - stepBack]);
						if (this.generatedDecisions[this.generatedDecisions.length - 1 - stepBack] == 
							compareGenerated[compareGenerated.length - 1 - stepBack])
						{
							multiply *= this.generatedDecisionSums[this.generatedDecisions.length - 1 - stepBack];
							//console.log("Multiplication result so far " + multiply);
						}
						else
						{
							multiply = 0;
							break;
						}
						stepBack++;
					}
					if ( k == -1 )
					{
						//TODO: Support multiple with same
						if (greatestMultiplication < multiply)
						{
							greatestMultiplicationName = decisionSet[i];
							greatestMultiplication = multiply; 
						}
					
					}
				}
				/*
				var previousPath = "";
				var newPath = "";
				for (var k = 0; k < this.generatedDecisions.length; k++)
				{
					previousPath = newPath;
					if (k != 0) newPath=newPath+",";
					newPath=newPath+this.generatedDecisions[k];
				}
				console.log("Decision path is now " + newPath + " it was " + previousPath);
				*/
				//generatedDecisionSums *= decisionSet[i];
			}
			//console.log("Sum for decision " + decisionSet[i] + " is " + allDecisionSums[i]);
			if (greatestSoFar >= 0) 
			{
				if (allDecisionSums[greatestSoFar] < allDecisionSums[i])
				{
					//console.log("This makes " + decisionSet[i] + " the greatest.");
					greatestSum = allDecisionSums[i];
					greatestSoFar = i;
					severalWithSame = false;
				} 
				//WARNING: is === safe here?
				else if (allDecisionSums[greatestSoFar] === allDecisionSums[i])
				{
		//			console.log("This makes " + decisionSet[i] + " same among equals.");
					severalWithSame = true;
				}
			//	console.log("Greatest is now " + greatestSoFar+ " and multiple " + severalWithSame);
			}
			else 
			{
				greatestSoFar = 0;
				greatestSum = allDecisionSums[i];
		//		console.log("Set greatest so far to zero and sum to " + greatestSum + " it is decision " + decisionSet[i]);
			}
		}
		var ret = [];
		if (greatestSum === 0)
		{
			if (greatestMultiplication > 0)
			{
				ret.push(greatestMultiplicationName);
			}
			else
			{
				//console.log("Return only the default");
				ret.push("default");
			}
			return ret;
		}
		else
		{
			if (includeAllAboveZeroIfMultiple)
			{
				//console.log("Including all above zero!");
				for (var j = 0; j < allDecisionSums.length; j++)
				{
					if (allDecisionSums[j] > 0 ) 
					{
						ret.push(decisionSet[j]);
						//console.log(decisionSet[j] + " got pushed into the displayable ones with score " + allDecisionSums[j]);
					}
				}
				if (greatestMultiplication > 0)
				{
					ret.push(greatestMultiplicationName);
				}
				return ret;
			}
			if (severalWithSame)
			{
				//console.log("Return from path 1");
				for (var j = 0; j < allDecisionSums.length; j++)
				{
					if (allDecisionSums[j] === greatestSum) 
					{
						ret.push(decisionSet[j]);
						//console.log(decisionSet[j] + " got pushed into the displayable ones with score " + greatestSum);
					}
				}
				//console.log("Return from path 1 :" + greatestSum + " : " + ret);
				if (greatestMultiplication > 0)
				{
					ret.push(greatestMultiplicationName);
				}
				return ret;
			}
			else
			{
				//console.log("Return from path 2 :" + greatestSoFar + " : " + decisionSet[greatestSoFar]);
				ret.push(decisionSet[greatestSoFar]);
				if (greatestMultiplication > 0)
				{
					ret.push(greatestMultiplicationName);
				}
				return ret;
			}
		}
	}
}

export default Calculator;

