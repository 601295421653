import React from 'react';
import ReactDOM from 'react-dom';
import { Menu, Dropdown, Icon } from 'antd';
import 'antd/dist/antd.css';

import Input from './Input.js';

class InputPlainDropdown extends Input {

	render() {
		return <div ref="dropdowncontainer" />;
	}


	menu = (
 	 	<Menu>
    			<Menu.Item>
      				1st menu item
  	  		</Menu.Item>
    			<Menu.Item>
      				2nd menu item
    			</Menu.Item>
    			<Menu.Item>
      				3rd menu item
    			</Menu.Item>
  		</Menu>
	);

	componentDidMount()
	{
		var dropdowncontainer = this.refs.dropdowncontainer;
		ReactDOM.render(
  			<Dropdown overlay={this.menu}>
    				<a className="ant-dropdown-link" href="#">
      					Hover me <Icon type="down" />
    				</a>
  			</Dropdown>, dropdowncontainer);
	}
}

export default InputPlainDropdown;
