import React from 'react';
import ReactDOM from 'react-dom';
import { Select } from 'antd';
import Input from './Input.js';

const Option = Select.Option;

class InputButtonDropdown extends Input {

	render() {
		return <div ref="dropdowncontainer2" />;
	}


	componentDidMount()
	{
		var options = [];
		options.push( <Option value="123"> 1st menu item </Option> );
		options.push( <Option value="456"> 2st menu item </Option> );
		options.push( <Option value="789"> 3st menu item </Option> );
		var dropdowncontainer2 = this.refs.dropdowncontainer2;
		ReactDOM.render(<div> <Select>{options}</Select></div>, dropdowncontainer2);
	}
}

export default InputButtonDropdown;
