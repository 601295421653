import React from 'react';

import Input from './Input.js';

class InputXYMap extends Input 
{
	size=225;	
	//JES: Ugly quickhack:
	divisorsx = [[100,3], [125,4], [150,5], [175,6], [200,7], [225,8], [250,9], [300,10]];
	divisorsy = [[100,6], [125,7], [150,9], [175,11], [200,12], [225,15], [250,16], [300,20]];
	xcoordname = "Quantity X";
	ycoordname = "Quantity Y";


	constructor(props)
	{
		super(props);
	}

	//currentCircle = null;

	clickBound(clickEvent)
	{
		var pt = this.refs.xymap.createSVGPoint();
		pt.x = clickEvent.clientX;
		pt.y = clickEvent.clientY;
		var cursorpt =  pt.matrixTransform(this.refs.xymap.getScreenCTM().inverse());
    		this.refs.circleref.setAttribute("cx", cursorpt.x);
    		this.refs.circleref.setAttribute("cy", cursorpt.y);
		console.log("(" + (cursorpt.x - (this.size/2)) + ", " + (-(cursorpt.y - (this.size/2))) + ")");
	}
	

	tablelookup(table)
	{
		var i;
		for (i = 0; i < table.length; i++)
		{
			if (this.size <= table[i][0]) return table[i][1];
		}
		return table[i-1][1];
	}
	
	render() {
		return (
			<table><tbody><tr><td>
			<svg ref="xymap" width={this.size} height={this.size} onClick={(clickEvent) => this.clickBound(clickEvent)}>
				<rect style={{stroke:"cornflowerblue", opacity:0.5}} x={0} rx={this.size} y={this.size/2} ry={this.size/2} width={this.size} height={1}/>
				<rect style={{stroke:"cornflowerblue", opacity:0.5}} x={this.size/2} rx={this.size/2} y={0} ry={this.size} height={this.size} width={1}/>
				<circle ref="circleref" style={{stroke:"cornflowerblue", opacity:0.7, fill:"white"}} cx={this.size/2} cy={this.size/2} r={5} />
			</svg>
			</td>
			<td style={{textAlign:"center", 
				transform:"translate(-"+this.size/this.tablelookup(this.divisorsx)+"px,0px) rotate(-90.0deg)", 
				whiteSpace:"no-wrap"}}
			>{this.ycoordname}</td></tr>
			<tr><td style={{textAlign:"center", whiteSpace:"no-wrap",transform:"translate(0px,-"+this.size/this.tablelookup(this.divisorsy)+"px)"}}>{this.xcoordname}</td><td></td></tr></tbody></table>
    		);
	}
}

export default InputXYMap;
